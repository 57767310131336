import { Form } from "../formsTypes";

const batForms: Form[] = [
  {
    name: "Isolation de combles ou de toitures",
    id: "BAT-EN-101",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/BAT-EN-101.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "geo",
        title: "Montant en kWh cumac par m² d'isolant",
        type: "value",
        value: ":H:2600:H:2100:H:1400",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux, Enseignement, Commerces", value: "1" },
          { name: "Hôtellerie - Restauration", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Autres secteurs", value: "4" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.6" },
          { name: "2", value: "0.7" },
          { name: "3", value: "1.2" },
          { name: "4", value: "0.6" },
        ],
      },
      {
        id: "surface",
        title: "Surface d'isolant en m²",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T01:00:00.000Z",
    history: [
      {
        name: "Isolation de combles ou de toitures",
        id: "BAT-EN-101",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-101.pdf",
        type: "Bâtiment",
        fields: [
          {
            id: "geo",
            title: "Montant en kWh cumac par m² d'isolant",
            type: "value",
            value: ":H:2600:H:2100:H:1400",
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux, Enseignement, Commerces", value: "1" },
              { name: "Hôtellerie - Restauration", value: "2" },
              { name: "Santé", value: "3" },
              { name: "Autres secteurs", value: "4" },
            ],
          },
          {
            id: "sector",
            title: "Facteur correctif selon secteur d'activité",
            type: "multichoice",
            choices: [
              { name: "1", value: "0.6" },
              { name: "2", value: "0.7" },
              { name: "3", value: "1.2" },
              { name: "4", value: "0.6" },
            ],
          },
          {
            id: "surface",
            title: "Surface d'isolant en m²",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Isolation des murs",
    id: "BAT-EN-102",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/BAT-EN-102.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "energy",
        title: "Énergie de chauffage",
        type: "choice",
        choices: [
          { name: "Électricité", value: ":H:3000:H:2500:H:1600" },
          { name: "Combustible", value: ":H:4800:H:3900:H:2600" },
        ],
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux, Enseignement, Commerces", value: "1" },
          { name: "Hôtellerie - Restauration", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Autres secteurs", value: "4" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.6" },
          { name: "2", value: "0.7" },
          { name: "3", value: "1.3" },
          { name: "4", value: "0.6" },
        ],
      },
      {
        id: "surface",
        title: "Surface d'isolant en m²",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T01:00:00.000Z",
    history: [
      {
        name: "Isolation des murs",
        id: "BAT-EN-102",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-102.pdf",
        type: "Bâtiment",
        fields: [
          {
            id: "energy",
            title: "Énergie de chauffage",
            type: "choice",
            choices: [
              { name: "Électricité", value: ":H:3000:H:2500:H:1600" },
              { name: "Combustible", value: ":H:4800:H:3900:H:2600" },
            ],
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux, Enseignement, Commerces", value: "1" },
              { name: "Hôtellerie - Restauration", value: "2" },
              { name: "Santé", value: "3" },
              { name: "Autres secteurs", value: "4" },
            ],
          },
          {
            id: "sector",
            title: "Facteur correctif selon secteur d'activité",
            type: "multichoice",
            choices: [
              { name: "1", value: "0.6" },
              { name: "2", value: "0.7" },
              { name: "3", value: "1.3" },
              { name: "4", value: "0.6" },
            ],
          },
          {
            id: "surface",
            title: "Surface d'isolant en m²",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Isolation d'un plancher",
    id: "BAT-EN-103",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/BAT-EN-103.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "geo",
        title: "Montant en kWh cumac par m² d'isolant",
        type: "value",
        value: ":H:5200:H:4200:H:2800",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux, Enseignement, Commerces", value: "1" },
          { name: "Hôtellerie - Restauration", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Autres secteurs", value: "4" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.6" },
          { name: "2", value: "0.7" },
          { name: "3", value: "1.2" },
          { name: "4", value: "0.6" },
        ],
      },
      {
        id: "surface",
        title: "Surface d'isolant en m²",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T01:00:00.000Z",
    history: [
      {
        name: "Isolation d'un plancher",
        id: "BAT-EN-103",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-103.pdf",
        type: "Bâtiment",
        fields: [
          {
            id: "geo",
            title: "Montant en kWh cumac par m² d'isolant",
            type: "value",
            value: ":H:5200:H:4200:H:2800",
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux, Enseignement, Commerces", value: "1" },
              { name: "Hôtellerie - Restauration", value: "2" },
              { name: "Santé", value: "3" },
              { name: "Autres secteurs", value: "4" },
            ],
          },
          {
            id: "sector",
            title: "Facteur correctif selon secteur d'activité",
            type: "multichoice",
            choices: [
              { name: "1", value: "0.6" },
              { name: "2", value: "0.7" },
              { name: "3", value: "1.2" },
              { name: "4", value: "0.6" },
            ],
          },
          {
            id: "surface",
            title: "Surface d'isolant en m²",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Fenêtre ou porte-fenêtre complète avec vitrage isolant",
    id: "BAT-EN-104",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-104.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "energy",
        title: "Énergie de chauffage",
        type: "value",
        value: ":H:5300:H:4300:H:2900",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux, Enseignement, Commerces", value: "1" },
          { name: "Hôtellerie - Restauration", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Autres secteurs", value: "4" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.6" },
          { name: "2", value: "0.7" },
          { name: "3", value: "1.3" },
          { name: "4", value: "0.6" },
        ],
      },
      {
        id: "surface",
        title: "Surface totale des fenêtres et portesfenêtres (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Isolation de combles ou de toitures (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-EN-106",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/BAT-EN-106.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "type",
        title: "Type de bâtiment",
        type: "choice",
        choices: [
          { name: "Bâtiment existant", value: "1" },
          { name: "Bâtiment neuf", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Commerce", value: "2" },
          { name: "Hôtellerie", value: "3" },
          { name: "Enseignement", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "type|sector",
        title: "Montant en kWh cumac par m² d'isolant posé",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "1400" },
          { name: "1|2", value: "1800" },
          { name: "1|3", value: "2800" },
          { name: "1|4", value: "1600" },
          { name: "1|5", value: "2500" },
          { name: "1|6", value: "1400" },
          { name: "2|1", value: "1000" },
          { name: "2|2", value: "1300" },
          { name: "2|3", value: "2000" },
          { name: "2|4", value: "1100" },
          { name: "2|5", value: "1800" },
          { name: "2|6", value: "1000" },
        ],
      },
      {
        id: "area",
        title: "Surface d'isolant posé (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T01:00:00.000Z",
    history: [
      {
        name: "Isolation de combles ou de toitures (France d'outre-mer)",
        metDomTom: 2,
        id: "BAT-EN-106",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-106.pdf",
        type: "Bâtiment",
        fields: [
          {
            id: "type",
            title: "Type de bâtiment",
            type: "choice",
            choices: [
              { name: "Bâtiment existant", value: "1" },
              { name: "Bâtiment neuf", value: "2" },
            ],
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux", value: "1" },
              { name: "Commerce", value: "2" },
              { name: "Hôtellerie", value: "3" },
              { name: "Enseignement", value: "4" },
              { name: "Santé", value: "5" },
              { name: "Autres secteurs", value: "6" },
            ],
          },
          {
            id: "type|sector",
            title: "Montant en kWh cumac par m² d'isolant posé",
            type: "multichoice",
            choices: [
              { name: "1|1", value: "1400" },
              { name: "1|2", value: "1800" },
              { name: "1|3", value: "2800" },
              { name: "1|4", value: "1600" },
              { name: "1|5", value: "2500" },
              { name: "1|6", value: "1400" },
              { name: "2|1", value: "1000" },
              { name: "2|2", value: "1300" },
              { name: "2|3", value: "2000" },
              { name: "2|4", value: "1100" },
              { name: "2|5", value: "1800" },
              { name: "2|6", value: "1000" },
            ],
          },
          {
            id: "area",
            title: "Surface d'isolant posé (m²)",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Isolation des toitures-terrasses",
    id: "BAT-EN-107",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/BAT-EN-107.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "energy",
        title: "Énergie de chauffage",
        type: "choice",
        choices: [
          { name: "Électricité", value: ":H:1800:H:1500:H:1000" },
          { name: "Combustible", value: ":H:2800:H:2300:H:1500" },
        ],
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux, Enseignement, Commerces", value: "1" },
          { name: "Hôtellerie - Restauration", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Autres secteurs", value: "4" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.6" },
          { name: "2", value: "0.7" },
          { name: "3", value: "1.3" },
          { name: "4", value: "0.6" },
        ],
      },
      {
        id: "surface",
        title: "Surface d'isolant en m²",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T01:00:00.000Z",
    history: [
      {
        name: "Isolation des toitures-terrasses",
        id: "BAT-EN-107",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-107.pdf",
        type: "Bâtiment",
        fields: [
          {
            id: "energy",
            title: "Énergie de chauffage",
            type: "choice",
            choices: [
              { name: "Électricité", value: ":H:1800:H:1500:H:1000" },
              { name: "Combustible", value: ":H:2800:H:2300:H:1500" },
            ],
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux, Enseignement, Commerces", value: "1" },
              { name: "Hôtellerie - Restauration", value: "2" },
              { name: "Santé", value: "3" },
              { name: "Autres secteurs", value: "4" },
            ],
          },
          {
            id: "sector",
            title: "Facteur correctif selon secteur d'activité",
            type: "multichoice",
            choices: [
              { name: "1", value: "0.6" },
              { name: "2", value: "0.7" },
              { name: "3", value: "1.3" },
              { name: "4", value: "0.6" },
            ],
          },
          {
            id: "surface",
            title: "Surface d'isolant en m²",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Isolation des murs (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-EN-108",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-108.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "type",
        title: "Type de bâtiment",
        type: "choice",
        choices: [
          { name: "Bâtiment existant", value: "1" },
          { name: "Bâtiment neuf", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Commerce", value: "2" },
          { name: "Hôtellerie", value: "3" },
          { name: "Enseignement", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "type|sector",
        title: "Montant en kWh cumac par m² d'isolant posé",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "920" },
          { name: "1|2", value: "760" },
          { name: "1|3", value: "1400" },
          { name: "1|4", value: "810" },
          { name: "1|5", value: "1300" },
          { name: "1|6", value: "760" },
          { name: "2|1", value: "660" },
          { name: "2|2", value: "540" },
          { name: "2|3", value: "1000" },
          { name: "2|4", value: "580" },
          { name: "2|5", value: "960" },
          { name: "2|6", value: "540" },
        ],
      },
      {
        id: "area",
        title: "Surface d'isolant posé (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Réduction des apports solaires par la toiture (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-EN-109",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-109.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "type",
        title: "Type de bâtiment",
        type: "choice",
        choices: [
          { name: "Bâtiment existant", value: "1" },
          { name: "Bâtiment neuf", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Commerces", value: "2" },
          { name: "Hôtellerie", value: "3" },
          { name: "Enseignement", value: "4" },
          { name: "Santé", value: "5" },
        ],
      },
      {
        id: "location",
        title: "Localisation",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [{ name: "Réunion, Mayotte, Guyane, Martinique, Guadeloupe", value: "1" }],
      },
      {
        id: "location",
        title: "Localisation",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "Réunion, Mayotte, Guyane", value: "1" },
          { name: "Martinique, Guadeloupe", value: "2" },
        ],
      },
      {
        id: "sector|location",
        title: "Montant de certificats en kWh cumac par m² de toiture protégée",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "1|1", value: "1200" },
          { name: "2|1", value: "1300" },
          { name: "3|1", value: "2100" },
          { name: "4|1", value: "1300" },
          { name: "5|1", value: "2000" },
        ],
      },
      {
        id: "sector|location",
        title: "Montant de certificats en kWh cumac par m² de toiture protégée",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "1|1", value: "860" },
          { name: "1|2", value: "0" },
          { name: "2|1", value: "950" },
          { name: "2|2", value: "0" },
          { name: "3|1", value: "1600" },
          { name: "3|2", value: "1600" },
          { name: "4|1", value: "900" },
          { name: "4|2", value: "900" },
          { name: "5|1", value: "1400" },
          { name: "5|2", value: "1400" },
        ],
      },
      {
        id: "area",
        title: "Surface de la toiture protégée (m²)",
        type: "number",
      },
    ],
    attachments: ["Note de calcul (dans le cas de la pose d'éléments séparés)"],
  },
  {
    name: "Protections des baies contre le rayonnement solaire (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-EN-110",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-110.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "solarFactor",
        title: "Facteur solaire (FS)",
        type: "choice",
        choices: [
          { name: "0.25 < FS ≤ 0.4", value: "1" },
          { name: "FS ≤ 0.25", value: "2" },
        ],
      },
      {
        id: "type",
        title: "Type de bâtiment",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Bâtiment existant", value: "1" },
          { name: "Bâtiment neuf", value: "2" },
        ],
      },
      {
        id: "usage",
        title: "Usage du bâtiment",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "Bureau ou commerce en Martinique et Guadeloupe", value: "1" },
          { name: "Autres", value: "2" },
        ],
      },
      {
        id: "solarFactor",
        title: "Montant en kWh cumac par m² de surface de baie protégée",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "1", value: "1100" },
          { name: "2", value: "1600" },
        ],
      },
      {
        id: "solarFactor|usage",
        title: "Montant en kWh cumac par m² de surface de baie protégée",
        type: "multichoice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "1|1", value: "330" },
          { name: "1|2", value: "760" },
          { name: "2|1", value: "640" },
          { name: "2|2", value: "1100" },
        ],
      },
      {
        id: "area",
        title: "Surface totale de baie (m²)",
        type: "number",
      },
    ],
    attachments: ["Justification de la valeur du facteur solaire"],
  },
  {
    name: "Fenêtre ou porte-fenêtre complète avec vitrage pariétodynamique (France métropolitaine)",
    metDomTom: 1,
    id: "BAT-EN-111",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-111.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Hôtellerie - restauration", value: "2" },
          { name: "Commerces", value: "3" },
          { name: "Enseignement", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Montant en kWh cumac par m²",
        type: "multichoice",
        choices: [
          { name: "1", value: ":H:3300:H:2800:H:2100" },
          { name: "2", value: ":H:3700:H:3200:H:2300" },
          { name: "3", value: ":H:3300:H:2900:H:2100" },
          { name: "4", value: ":H:4000:H:3500:H:2500" },
          { name: "5", value: ":H:6600:H:5500:H:3900" },
          { name: "6", value: ":H:3300:H:2800:H:2100" },
        ],
      },
      {
        id: "area",
        title: "Surface de fenêtres ou portes-fenêtres complètes pariétodynamiques posées (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Revêtements réflectifs en toiture",
    id: "BAT-EN-112",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-112.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac pour un m² de toiture couvert par un revêtement réflectif",
        type: "value",
        value: ":H:160:H:170:H:270",
      },
      {
        id: "area",
        title: "Surface de toiture en m² couvert par un revêtement réflectif",
        type: "number",
      },
    ],
    attachments: ["Document issu du fabricant relatif au lot utilisé"],
  },
  {
    name: "Façade rideau ou semi-rideau avec vitrage isolant",
    id: "BAT-EN-113",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EN-113.pdf",
    type: "Thermique",
    fields: [
      {
        id: "ratio",
        title: "Montant en kWh cumac par m² de façade rideau ou semi-rideau avec vitrage isolant",
        type: "value",
        value: ":H:5900:H:4800:H:3200",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux, Enseignement, Commerces", value: "1" },
          { name: "Hôtellerie-restauration", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Autres secteurs", value: "4" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.6" },
          { name: "2", value: "0.7" },
          { name: "3", value: "1.3" },
          { name: "4", value: "0.6" },
        ],
      },
      {
        id: "area",
        title: "Surface totale de façade rideau ou semi-rideau avec vitrage isolant (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Installation frigorifique utilisant du CO2 subcritique ou transcritique",
    id: "BAT-EQ-117",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-117.pdf",
    type: "Froid",
    fields: [
      {
        id: "type",
        title: "Type d'installation",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "CO2 frigoporteur diphasique pour production de froid positif", value: "1" },
          { name: "Froid négatif seul en cascade avec CO2 subcritique", value: "2" },
          {
            name: "Froid positif et négatif ou positif seul ou négatif seul, mise en place d'une installation au CO2 transcritique",
            value: "3",
          },
        ],
      },
      {
        id: "amount",
        title: "Montant en kWh cumac par kW",
        type: "value",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        value: "7300",
      },
      {
        id: "amount",
        title: "Montant en kWh cumac par kW",
        type: "value",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        value: "8400",
      },
      {
        id: "powerPlus",
        title:
          "Puissance frigorifique utile délivrée par les compresseurs positifs neufs moyenne température au CO2 en kW",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        type: "number",
      },
      {
        id: "powerMinus",
        title:
          "Puissance frigorifique utile délivrée par les compresseurs négatifs neufs basse température au CO2 en kW",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        type: "number",
      },
      {
        id: "option",
        title: "Option installée",
        type: "choice",
        choices: [
          { name: "Option 0", value: "1" },
          { name: "Option 1 ou 1 bis", value: "2" },
          { name: "Option 2", value: "3" },
        ],
        showIf: { fieldId: "type", fieldValues: ["3"] },
      },
      {
        id: "alimentation",
        title: "Alimentation des évaporateurs en régime saturé",
        type: "choice",
        choices: [
          { name: "Sans", value: "1" },
          { name: "Avec (Option 3)", value: "2" },
        ],
        showIf: { fieldId: "type", fieldValues: ["3"] },
      },
      {
        id: "option|alimentation",
        title: "Montants en kWh cumac / kW",
        showIf: { fieldId: "type", fieldValues: ["3"] },
        type: "multichoice",
        choices: [
          { name: "1|1", value: "8500" },
          { name: "1|2", value: "12500" },
          { name: "2|1", value: "10300" },
          { name: "2|2", value: "14100" },
          { name: "3|1", value: "12700" },
          { name: "3|2", value: "16300" },
        ],
      },
      {
        id: "power",
        title: "Somme des puissances frigorifiques utiles délivrées par les compresseurs CO2 en kW (Pf+ + Pf-)",
        showIf: { fieldId: "type", fieldValues: ["3"] },
        type: "number",
      },
    ],
    attachments: ["Rapport du professionnel"],
  },
  {
    name: "Moto-variateur synchrone à aimants permanents ou à réluctance",
    id: "BAT-EQ-123",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-123.pdf",
    type: "Moteur",
    fields: [
      {
        id: "application",
        title: "Application",
        type: "choice",
        choices: [
          { name: "Chauffage, pompage", value: "1" },
          { name: "Ventilation, renouvellement d'air", value: "2" },
          { name: "Réfrigération", value: "3" },
          { name: "Climatisation", value: "4" },
          { name: "Autres applications", value: "5" },
        ],
      },
      {
        id: "application",
        title: "Application",
        type: "multichoice",
        choices: [
          { name: "1", value: "13700" },
          { name: "2", value: "16300" },
          { name: "3", value: "8000" },
          { name: "4", value: "2000" },
          { name: "5", value: "2000" },
        ],
      },
      {
        id: "power",
        title: "Puissance électrique nominale du moto-variateur en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Fermeture des meubles frigorifiques de vente à température positive",
    id: "BAT-EQ-124",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-124.pdf",
    type: "Froid",
    fields: [
      {
        id: "ratio",
        title: "Montant unitaire en kWh cumac par mètre linéaire",
        type: "value",
        value: "25600",
      },
      {
        id: "length",
        title: "Longueur linéaire de porte en verre (en m)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Fermeture des meubles frigorifiques de vente à température négative",
    id: "BAT-EQ-125",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-125.pdf",
    type: "Froid",
    fields: [
      {
        id: "type",
        title: "Type de meuble frigorifique équipé de couvercles",
        type: "choice",
        choices: [
          { name: "Simple", value: "6700" },
          { name: "Double", value: "8200" },
          { name: "Combiné", value: "4600" },
        ],
      },
      {
        id: "length",
        title: "Longueur totale de couvercles installés (m)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Luminaire d'éclairage général à modules LED",
    id: "BAT-EQ-127",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-127.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "irc",
        title: "IRC",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Inférieur à 90", value: "1" },
          { name: "Supérieur ou égal à 90 avec R9 > 0", value: "2" },
        ],
      },
      {
        id: "efficiency",
        title: "Efficacité lumineuse",
        type: "choice",
        showIf: { fieldId: "irc", fieldValues: ["1"] },
        choices: [
          { name: "Entre 120 et 139 lm/W", value: "1" },
          { name: "Entre 140 et 159 lm/W", value: "2" },
          { name: "Entre 160 et 184 lm/W", value: "3" },
          { name: "Supérieure ou égale à 185 lm/W", value: "4" },
        ],
      },
      {
        id: "efficiency",
        title: "Efficacité lumineuse",
        type: "choice",
        showIf: { fieldId: "irc", fieldValues: ["2"] },
        choices: [
          { name: "Entre 108 et 125 lm/W", value: "1" },
          { name: "Entre 126 et 143 lm/W", value: "2" },
          { name: "Entre 144 et 166 lm/W", value: "3" },
          { name: "Supérieure ou égale à 167 lm/W", value: "4" },
        ],
      },
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Hôtellerie", value: "1" },
          { name: "Santé / entrepôts / Commerce ≥ 400 m²", value: "2" },
          { name: "Enseignement", value: "3" },
          { name: "Commerce < 400 m²", value: "4" },
          { name: "Bureaux - restauration", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "sector|efficiency",
        title: "Montant de kWh cumac selon secteur d'activité",
        type: "multichoice",
        showIf: { fieldId: "irc", fieldValues: ["1"] },
        choices: [
          { name: "1|1", value: "47" },
          { name: "1|2", value: "47" },
          { name: "1|3", value: "59" },
          { name: "1|4", value: "74" },
          { name: "2|1", value: "42" },
          { name: "2|2", value: "42" },
          { name: "2|3", value: "54" },
          { name: "2|4", value: "67" },
          { name: "3|1", value: "27" },
          { name: "3|2", value: "28" },
          { name: "3|3", value: "35" },
          { name: "3|4", value: "44" },
          { name: "4|1", value: "53" },
          { name: "4|2", value: "54" },
          { name: "4|3", value: "67" },
          { name: "4|4", value: "83" },
          { name: "5|1", value: "35" },
          { name: "5|2", value: "35" },
          { name: "5|3", value: "44" },
          { name: "5|4", value: "55" },
          { name: "6|1", value: "27" },
          { name: "6|2", value: "28" },
          { name: "6|3", value: "35" },
          { name: "6|4", value: "44" },
        ],
      },
      {
        id: "sector|efficiency",
        title: "Montant de kWh cumac selon secteur d'activité",
        type: "multichoice",
        showIf: { fieldId: "irc", fieldValues: ["2"] },
        choices: [
          { name: "1|1", value: "38" },
          { name: "1|2", value: "39" },
          { name: "1|3", value: "50" },
          { name: "1|4", value: "63" },
          { name: "2|1", value: "34" },
          { name: "2|2", value: "35" },
          { name: "2|3", value: "45" },
          { name: "2|4", value: "57" },
          { name: "3|1", value: "22" },
          { name: "3|2", value: "23" },
          { name: "3|3", value: "29" },
          { name: "3|4", value: "37" },
          { name: "4|1", value: "45" },
          { name: "4|2", value: "45" },
          { name: "4|3", value: "57" },
          { name: "4|4", value: "71" },
          { name: "5|1", value: "29" },
          { name: "5|2", value: "29" },
          { name: "5|3", value: "37" },
          { name: "5|4", value: "47" },
          { name: "6|1", value: "22" },
          { name: "6|2", value: "23" },
          { name: "6|3", value: "29" },
          { name: "6|4", value: "37" },
        ],
      },
      {
        id: "power",
        title: "Puissance totale des luminaires à modules LED installés (P en watt)",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable", "Qualification du professionnel ayant effectué cette étude"],
  },
  {
    name: "Lanterneaux d'éclairage zénithal (France Métropolitaine)",
    metDomTom: 1,
    id: "BAT-EQ-129",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-129.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Commerces", value: "1" },
          { name: "Autres secteurs", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Montant en kWh cumac par m²",
        type: "multichoice",
        choices: [
          { name: "1", value: ":H:9500:H:10800:H:16000" },
          { name: "2", value: ":H:3400:H:4000:H:6400" },
        ],
      },
      {
        id: "area",
        title: "Aire de la projection horizontale de la surface éclairante en m²",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Système de condensation frigorifique à haute efficacité",
    id: "BAT-EQ-130",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-130.pdf",
    type: "Froid",
    fields: [
      {
        id: "type",
        title: "Type du système de condensation frigorifique",
        type: "choice",
        choices: [
          { name: "Condenseur à eau seul (sur nappe, cours d'eau ou autre)", value: "1" },
          { name: "Condenseur à air sec (adiabatique ou non)", value: "2_1" },
          { name: "Condenseur à eau et aéroréfrigérant à air sec (adiabatique ou non)", value: "2_2" },
          { name: "Condenseur évaporatif (hybride ou non)", value: "3_1" },
          { name: "Tour aéroréfrigérante ouverte (hybride ou non) et condenseur à eau", value: "3_2" },
          { name: "Tour aéroréfrigérante fermée (hybride ou non) et condenseur à eau", value: "3_3" },
        ],
      },
      {
        id: "deltaTemp",
        title: "∆T en °C",
        type: "range",
        choiceRanges: {
          fieldId: "type",
          valuesRanges: [
            { fieldValue: "1", range: "8>6" },
            { fieldValue: "2", range: "12>0" },
            { fieldValue: "3", range: "22>10" },
          ],
        },
      },
      {
        id: "application",
        title: "Application",
        type: "choice",
        choices: [
          { name: "Climatisation de confort hors datacenter", value: "1" },
          { name: "Climatisation en datacenter", value: "2" },
          { name: "Réfrigération ou conditionnement d'ambiance hors confort", value: "3" },
        ],
      },
      {
        id: "type|deltaTemp|application",
        title: "Montant en kWh cumac par kW selon l'application",
        type: "multichoice",
        choices: [
          { name: "1|8|1", value: "500" },
          { name: "1|8|2", value: "1900" },
          { name: "1|8|3", value: "1300" },
          { name: "1|7|1", value: "770" },
          { name: "1|7|2", value: "3000" },
          { name: "1|7|3", value: "2000" },
          { name: "1|6|1", value: "1100" },
          { name: "1|6|2", value: "4100" },
          { name: "1|6|3", value: "2700" },
          { name: "2|12|1", value: "580" },
          { name: "2|12|2", value: "2300" },
          { name: "2|12|3", value: "1600" },
          { name: "2|11|1", value: "790" },
          { name: "2|11|2", value: "3100" },
          { name: "2|11|3", value: "2200" },
          { name: "2|10|1", value: "1000" },
          { name: "2|10|2", value: "3900" },
          { name: "2|10|3", value: "2800" },
          { name: "2|9|1", value: "1200" },
          { name: "2|9|2", value: "4800" },
          { name: "2|9|3", value: "3400" },
          { name: "2|8|1", value: "1500" },
          { name: "2|8|2", value: "5800" },
          { name: "2|8|3", value: "4000" },
          { name: "2|7|1", value: "1700" },
          { name: "2|7|2", value: "6800" },
          { name: "2|7|3", value: "4700" },
          { name: "2|6|1", value: "2000" },
          { name: "2|6|2", value: "7800" },
          { name: "2|6|3", value: "5300" },
          { name: "2|5|1", value: "2300" },
          { name: "2|5|2", value: "8900" },
          { name: "2|5|3", value: "6000" },
          { name: "2|4|1", value: "2600" },
          { name: "2|4|2", value: "10100" },
          { name: "2|4|3", value: "6800" },
          { name: "2|3|1", value: "2900" },
          { name: "2|3|2", value: "11300" },
          { name: "2|3|3", value: "7500" },
          { name: "2|2|1", value: "3200" },
          { name: "2|2|2", value: "12600" },
          { name: "2|2|3", value: "8300" },
          { name: "2|1|1", value: "3600" },
          { name: "2|1|2", value: "14000" },
          { name: "2|1|3", value: "9100" },
          { name: "2|0|1", value: "4000" },
          { name: "2|0|2", value: "15500" },
          { name: "2|0|3", value: "10000" },
          { name: "3|22|1", value: "580" },
          { name: "3|22|2", value: "2300" },
          { name: "3|22|3", value: "1600" },
          { name: "3|21|1", value: "790" },
          { name: "3|21|2", value: "3100" },
          { name: "3|21|3", value: "2200" },
          { name: "3|20|1", value: "1000" },
          { name: "3|20|2", value: "3900" },
          { name: "3|20|3", value: "2800" },
          { name: "3|19|1", value: "1200" },
          { name: "3|19|2", value: "4800" },
          { name: "3|19|3", value: "3400" },
          { name: "3|18|1", value: "1500" },
          { name: "3|18|2", value: "5800" },
          { name: "3|18|3", value: "4000" },
          { name: "3|17|1", value: "1700" },
          { name: "3|17|2", value: "6800" },
          { name: "3|17|3", value: "4700" },
          { name: "3|16|1", value: "2000" },
          { name: "3|16|2", value: "7800" },
          { name: "3|16|3", value: "5300" },
          { name: "3|15|1", value: "2300" },
          { name: "3|15|2", value: "8900" },
          { name: "3|15|3", value: "6000" },
          { name: "3|14|1", value: "2600" },
          { name: "3|14|2", value: "10100" },
          { name: "3|14|3", value: "6800" },
          { name: "3|13|1", value: "2900" },
          { name: "3|13|2", value: "11300" },
          { name: "3|13|3", value: "7500" },
          { name: "3|12|1", value: "3200" },
          { name: "3|12|2", value: "12600" },
          { name: "3|12|3", value: "8300" },
          { name: "3|11|1", value: "3600" },
          { name: "3|11|2", value: "14000" },
          { name: "3|11|3", value: "9100" },
          { name: "3|10|1", value: "4000" },
          { name: "3|10|2", value: "15500" },
          { name: "3|10|3", value: "10000" },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale totale du groupe de production de froid en kW",
        type: "number",
      },
    ],
    attachments: ["Note de calcul du dimensionnement"],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de condensation frigorifique à haute efficacité",
        id: "BAT-EQ-130",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-130.pdf",
        type: "Froid",
        fields: [
          {
            id: "type",
            title: "Type du système de condensation frigorifique",
            type: "choice",
            choices: [
              { name: "Condenseur à eau seul (sur nappe, cours d'eau ou autre)", value: "1" },
              { name: "Condenseur à air sec (adiabatique ou non)", value: "2_1" },
              { name: "Condenseur à eau et aéroréfrigérant à air sec (adiabatique ou non)", value: "2_2" },
              { name: "Condenseur évaporatif (hybride ou non)", value: "3_1" },
              { name: "Tour aéroréfrigérante ouverte (hybride ou non) et condenseur à eau", value: "3_2" },
              { name: "Tour aéroréfrigérante fermée (hybride ou non) et condenseur à eau", value: "3_3" },
            ],
          },
          {
            id: "deltaTemp",
            title: "∆T en °C",
            type: "range",
            choiceRanges: {
              fieldId: "type",
              valuesRanges: [
                { fieldValue: "1", range: "8>6" },
                { fieldValue: "2", range: "12>0" },
                { fieldValue: "3", range: "22>10" },
              ],
            },
          },
          {
            id: "application",
            title: "Application",
            type: "choice",
            choices: [
              { name: "Climatisation de confort hors datacenter", value: "1" },
              { name: "Climatisation en datacenter", value: "2" },
              { name: "Réfrigération ou conditionnement d'ambiance hors confort", value: "3" },
            ],
          },
          {
            id: "type|deltaTemp|application",
            title: "Montant en kWh cumac par kW selon l'application",
            type: "multichoice",
            choices: [
              { name: "1|8|1", value: "500" },
              { name: "1|8|2", value: "1900" },
              { name: "1|8|3", value: "1300" },
              { name: "1|7|1", value: "770" },
              { name: "1|7|2", value: "3000" },
              { name: "1|7|3", value: "2000" },
              { name: "1|6|1", value: "1100" },
              { name: "1|6|2", value: "4100" },
              { name: "1|6|3", value: "2700" },
              { name: "2|12|1", value: "580" },
              { name: "2|12|2", value: "2300" },
              { name: "2|12|3", value: "1600" },
              { name: "2|11|1", value: "790" },
              { name: "2|11|2", value: "3100" },
              { name: "2|11|3", value: "2200" },
              { name: "2|10|1", value: "1000" },
              { name: "2|10|2", value: "3900" },
              { name: "2|10|3", value: "2800" },
              { name: "2|9|1", value: "1200" },
              { name: "2|9|2", value: "4800" },
              { name: "2|9|3", value: "3400" },
              { name: "2|8|1", value: "1500" },
              { name: "2|8|2", value: "5800" },
              { name: "2|8|3", value: "4000" },
              { name: "2|7|1", value: "1700" },
              { name: "2|7|2", value: "6800" },
              { name: "2|7|3", value: "4700" },
              { name: "2|6|1", value: "2000" },
              { name: "2|6|2", value: "7800" },
              { name: "2|6|3", value: "5300" },
              { name: "2|5|1", value: "2300" },
              { name: "2|5|2", value: "8900" },
              { name: "2|5|3", value: "6000" },
              { name: "2|4|1", value: "2600" },
              { name: "2|4|2", value: "10100" },
              { name: "2|4|3", value: "6800" },
              { name: "2|3|1", value: "2900" },
              { name: "2|3|2", value: "11300" },
              { name: "2|3|3", value: "7500" },
              { name: "2|2|1", value: "3200" },
              { name: "2|2|2", value: "12600" },
              { name: "2|2|3", value: "8300" },
              { name: "2|1|1", value: "3600" },
              { name: "2|1|2", value: "14000" },
              { name: "2|1|3", value: "9100" },
              { name: "2|0|1", value: "4000" },
              { name: "2|0|2", value: "15500" },
              { name: "2|0|3", value: "10000" },
              { name: "3|22|1", value: "580" },
              { name: "3|22|2", value: "2300" },
              { name: "3|22|3", value: "1600" },
              { name: "3|21|1", value: "790" },
              { name: "3|21|2", value: "3100" },
              { name: "3|21|3", value: "2200" },
              { name: "3|20|1", value: "1000" },
              { name: "3|20|2", value: "3900" },
              { name: "3|20|3", value: "2800" },
              { name: "3|19|1", value: "1200" },
              { name: "3|19|2", value: "4800" },
              { name: "3|19|3", value: "3400" },
              { name: "3|18|1", value: "1500" },
              { name: "3|18|2", value: "5800" },
              { name: "3|18|3", value: "4000" },
              { name: "3|17|1", value: "1700" },
              { name: "3|17|2", value: "6800" },
              { name: "3|17|3", value: "4700" },
              { name: "3|16|1", value: "2000" },
              { name: "3|16|2", value: "7800" },
              { name: "3|16|3", value: "5300" },
              { name: "3|15|1", value: "2300" },
              { name: "3|15|2", value: "8900" },
              { name: "3|15|3", value: "6000" },
              { name: "3|14|1", value: "2600" },
              { name: "3|14|2", value: "10100" },
              { name: "3|14|3", value: "6800" },
              { name: "3|13|1", value: "2900" },
              { name: "3|13|2", value: "11300" },
              { name: "3|13|3", value: "7500" },
              { name: "3|12|1", value: "3200" },
              { name: "3|12|2", value: "12600" },
              { name: "3|12|3", value: "8300" },
              { name: "3|11|1", value: "3600" },
              { name: "3|11|2", value: "14000" },
              { name: "3|11|3", value: "9100" },
              { name: "3|10|1", value: "4000" },
              { name: "3|10|2", value: "15500" },
              { name: "3|10|3", value: "10000" },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale totale du groupe de production de froid en kW",
            type: "number",
          },
        ],
        attachments: ["Note de calcul du dimensionnement"],
      },
    ],
  },
  {
    name: "Conduits de lumière naturelle",
    id: "BAT-EQ-131",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-131.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par m²",
        type: "value",
        value: "28500",
      },
      {
        id: "sector",
        title: "Secteur d'application tertiaire",
        type: "choice",
        choices: [
          { name: "Commerce", value: "1" },
          { name: "Bureaux", value: "0.75" },
          { name: "Autres Secteurs", value: "0.6" },
        ],
      },
      {
        id: "ratio",
        title: "Coefficient Métropole / DOM-TOM",
        type: "value",
        value: ":MDT:1:MDT:1.5",
      },
      {
        id: "area",
        title: "Section totale S en m²",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Systèmes hydro-économes (France métropolitaine)",
    metDomTom: 1,
    id: "BAT-EQ-133",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-133.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "type",
        title: "Type de systèmes",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Pommes de douche", value: "1" },
          { name: "Aérateurs", value: "2" },
        ],
      },
      {
        id: "sectorShower",
        title: "Secteur d'activité",
        type: "choice",
        skipValue: true,
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "Santé", value: "0.85" },
          { name: "Hôtellerie et habitat communautaire", value: "1" },
          { name: "Etablissements sportifs", value: "4" },
        ],
      },
      {
        id: "sectorAerator",
        title: "Secteur d'activité",
        type: "choice",
        skipValue: true,
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "Bureaux", value: "1.7" },
          { name: "Enseignement", value: "4.3" },
          { name: "Hôtellerie et habitat communautaire", value: "1" },
          { name: "Santé", value: "0.85" },
          { name: "Etablissements sportifs", value: "4" },
          { name: "Autres secteurs", value: "0.3" },
        ],
      },
      {
        id: "nShowerZ",
        title: "Nombre de pommes de douche de classe Z mis en place",
        type: "number",
        skipValue: true,
        showIf: { fieldId: "type", fieldValues: ["1"] },
      },
      {
        id: "nShowerZZ",
        title: "Nombre de pommes de douche de classe ZZ ou Watersense mis en place",
        type: "number",
        skipValue: true,
        showIf: { fieldId: "type", fieldValues: ["1"] },
      },
      {
        id: "nAeratorZ",
        title: "Nombre d'aérateurs non régulés de classe Z mis en place sur des lavabos ou éviers",
        type: "number",
        skipValue: true,
        showIf: { fieldId: "type", fieldValues: ["2"] },
      },
      {
        id: "nAeratorAuto",
        title: "Nombre d'aérateurs auto-régulés mis en place sur des lavabos ou éviers",
        type: "number",
        skipValue: true,
        showIf: { fieldId: "type", fieldValues: ["2"] },
      },
      {
        id: "formula",
        title: "Montant de certificats en kWh cumac",
        type: "formula",
        value: "BATEQ133",
      },
    ],
    attachments: [],
  },
  {
    name: "Meuble frigorifique de vente performant avec groupe de production de froid intégré",
    id: "BAT-EQ-134",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-EQ-134.pdf",
    type: "Froid",
    fields: [
      {
        id: "class",
        title: "Classe énergétique des meubles",
        type: "choice",
        choices: [
          { name: "D", value: "1" },
          { name: "C", value: "2" },
          { name: "B", value: "3" },
          { name: "A", value: "4" },
        ],
      },
      {
        id: "type",
        title: "Type de meubles",
        type: "choice",
        choices: [
          { name: "Armoires frigorifiques verticales, semiverticales et mixtes", value: "1" },
          { name: "Armoires frigorifiques horizontales", value: "2" },
          { name: "Congélateurs verticaux et mixtes", value: "3" },
          { name: "Congélateurs horizontaux", value: "4" },
        ],
      },
      {
        id: "class|type",
        title: "Montant en kWh cumac par ml installé",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "22600" },
          { name: "1|2", value: "6300" },
          { name: "1|3", value: "18400" },
          { name: "1|4", value: "9900" },
          { name: "2|1", value: "31000" },
          { name: "2|2", value: "8700" },
          { name: "2|3", value: "30800" },
          { name: "2|4", value: "14700" },
          { name: "3|1", value: "38200" },
          { name: "3|2", value: "10500" },
          { name: "3|3", value: "41200" },
          { name: "3|4", value: "18800" },
          { name: "4|1", value: "43800" },
          { name: "4|2", value: "12100" },
          { name: "4|3", value: "49400" },
          { name: "4|4", value: "21900" },
        ],
      },
      {
        id: "length",
        title: "Longueur totale de meubles frigorifiques de vente installés (m)",
        type: "number",
      },
    ],
    attachments: ["Certification de l'équipement"],
  },
  {
    name: "Dispositif performant d’alimentation sans interruption",
    id: "BAT-EQ-135",
    formUrl: "https://gdceeforms.blob.core.windows.net/v65/BAT-EQ-135.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "activePower",
        title: "Tranche de puissance active de sortie assignée de l’ASI (kW)",
        type: "choice",
        choices: [
          { name: "100 kW < P ≤ 200 kW", value: "3100" },
          { name: "P > 200 kW", value: "2500" },
        ],
      },
      {
        id: "power",
        title: "Puissance active de sortie assignée de l’ASI (kW)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-04-01T02:00:00.000Z",
    history: [
      {
        name: "Dispositif performant d’alimentation sans interruption",
        id: "BAT-EQ-135",
        formUrl: "https://gdceeforms.blob.core.windows.net/v62/BAT-EQ-135.pdf",
        type: "Optimisation",
        fields: [
          {
            id: "activePower",
            title: "Tranche de puissance active de sortie assignée de l’ASI (kW)",
            type: "choice",
            choices: [
              { name: "100 kW < P ≤ 200 kW", value: "3100" },
              { name: "P > 200 kW", value: "2500" },
            ],
          },
          {
            id: "power",
            title: "Puissance active de sortie assignée de l’ASI (kW)",
            type: "number",
          },
        ],
        attachments: [],
        startDate: "2024-08-31T02:00:00.000Z",
      },
    ],
  },
  {
    name: "Réglage des organes d'équilibrage d'une installation de chauffage à eau chaude",
    id: "BAT-SE-103",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-SE-103.pdf",
    type: "Thermique",
    fields: [
      {
        id: "amount",
        title: "Montant de certificats en kWh cumac par m²",
        type: "value",
        value: ":H:120:H:100:H:67",
      },
      {
        id: "area",
        title: "Surface chauffée (m²)",
        type: "number",
      },
    ],
    attachments: [
      "Schéma hydraulique des installations de chauffage",
      "Grille d'équilibrage",
      "Tableau d'enregistrement des températures moyennes",
    ],
  },
  {
    name: "Contrat de Performance Energétique Services (CPE Services) Chauffage",
    id: "BAT-SE-104",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-SE-104.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "duration",
        title: "Durée de la garantie (année pleine)",
        type: "choice",
        choices: [
          { name: "2", value: ":H:23:H:19:H:13" },
          { name: "3", value: ":H:34:H:28:H:18" },
          { name: "4", value: ":H:44:H:36:H:24" },
          { name: "5", value: ":H:54:H:44:H:30" },
          { name: "6", value: ":H:64:H:52:H:35" },
          { name: "7", value: ":H:73:H:60:H:40" },
          { name: "8", value: ":H:82:H:67:H:45" },
          { name: "9", value: ":H:90:H:74:H:49" },
          { name: "10 ou plus", value: ":H:99:H:81:H:54" },
        ],
      },
      {
        id: "area",
        title: "Surface chauffée (m²)",
        type: "number",
      },
      {
        id: "fFactor",
        title: "Facteur correctif « F » lié au périmètre du contrat",
        type: "number",
      },
    ],
    attachments: ["Contrat signé justifiant la conformité", "Certificat Qualibat 553 ou 554 de l'opérateur"],
  },
  {
    name: "Abaissement de la température de retour vers un réseau de chaleur",
    id: "BAT-SE-105",
    metDomTom: 1, // Source: Aurelia's Excel file
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-SE-105.pdf",
    type: "Thermique",
    fields: [
      {
        id: "amount",
        title: "Montant de certificats en kWh cumac par m²",
        type: "value",
        value: ":H:130:H:110:H:72",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Commerces", value: "4" },
          { name: "Hôtellerie, restauration", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Coefficient correctif cumac selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "1.2" },
          { name: "2", value: "0.8" },
          { name: "3", value: "1" },
          { name: "4", value: "0.9" },
          { name: "5", value: "1.3" },
          { name: "6", value: "0.8" },
        ],
      },
      {
        id: "area",
        title: "Surface chauffée (m²)",
        type: "number",
      },
    ],
    attachments: ["Attestation d'absence de points de recyclage et de situation de débit constant"],
  },
  {
    name: "Chaudière collective à haute performance énergétique",
    id: "BAT-TH-102",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-102.pdf",
    type: "Thermique",
    fields: [
      {
        id: "usage",
        title: "Usage de la chaudière",
        type: "choice",
        choices: [
          { name: "Chauffage", value: "1" },
          { name: "Chauffage et ECS", value: "2" },
        ],
      },
      {
        id: "power",
        title: "Puissance de la chaudière",
        type: "choice",
        choices: [
          { name: "P ≤ 400 kW", value: "1" },
          { name: "P > 400 kW", value: "2" },
        ],
      },
      {
        id: "usage|power",
        title: "Montant en kWh cumac par m² de surface chauffée",
        type: "multichoice",
        choices: [
          { name: "1|1", value: ":H:370:H:300:H:200" },
          { name: "1|2", value: ":H:400:H:320:H:220" },
          { name: "2|1", value: ":H:430:H:360:H:240" },
          { name: "2|2", value: ":H:470:H:380:H:260" },
        ],
      },
      {
        id: "surface",
        title: "Surface chauffée (m²)",
        type: "number",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Commerces", value: "4" },
          { name: "Hôtellerie - restauration", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Coefficient correctif cumac selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "1" },
          { name: "2", value: "0.7" },
          { name: "3", value: "1.1" },
          { name: "4", value: "0.9" },
          { name: "5", value: "1.4" },
          { name: "6", value: "0.7" },
        ],
      },
      {
        id: "coef",
        title: "Coefficient R",
        type: "number",
      },
    ],
    attachments: [],
    endDate: "2025-01-01T02:00:00.000Z",
  },
  {
    name: "Plancher chauffant hydraulique à basse température",
    id: "BAT-TH-103",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-103.pdf",
    type: "Thermique",
    fields: [
      {
        id: "amount",
        title:
          "Montant en kWh cumac par m² de surface de bâtiment chauffée par un plancher chauffant basse température",
        type: "value",
        value: ":H:210:H:170:H:110",
      },
      {
        id: "area",
        title: "Surface chauffée (m²)",
        type: "number",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Commerce", value: "3" },
          { name: "Hôtellerie, restauration", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "1.2" },
          { name: "2", value: "0.8" },
          { name: "3", value: "0.9" },
          { name: "4", value: "1.3" },
          { name: "5", value: "0.9" },
          { name: "6", value: "0.8" },
        ],
      },
    ],
    attachments: [],
  },
  {
    name: "Robinet thermostatique",
    id: "BAT-TH-104",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-104.pdf",
    type: "Thermique",
    fields: [
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Commerces", value: "4" },
          { name: "Hôtellerie, restauration", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "1.2" },
          { name: "2", value: "0.8" },
          { name: "3", value: "1" },
          { name: "4", value: "0.9" },
          { name: "5", value: "1.3" },
          { name: "6", value: "0.8" },
        ],
      },
      {
        id: "area",
        title: "Surface chauffée en m²",
        type: "number",
      },
      {
        id: "amount",
        title: "Montant unitaire en kWh cumac/m²",
        type: "value",
        value: ":H:100:H:81:H:54",
      },
    ],
    attachments: [],
  },
  {
    name: "Radiateur basse température pour un chauffage central",
    id: "BAT-TH-105",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-105.pdf",
    type: "Thermique",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par m² de surface de bâtiment chauffée par les radiateurs basse température",
        type: "value",
        value: ":H:56:H:46:H:31",
      },
      {
        id: "area",
        title: "Surface chauffée en m²",
        type: "number",
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Commerces", value: "4" },
          { name: "Hôtellerie, restauration", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif",
        type: "multichoice",
        choices: [
          { name: "1", value: "1.2" },
          { name: "2", value: "0.8" },
          { name: "3", value: "1" },
          { name: "4", value: "0.9" },
          { name: "5", value: "1.3" },
          { name: "6", value: "0.8" },
        ],
      },
    ],
    attachments: [],
  },
  {
    name: "Système de régulation par programmation d'intermittence",
    id: "BAT-TH-108",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-108.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Commerces", value: "3" },
          { name: "Hôtellerie-Restauration", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "energy",
        title: "Énergie de chauffage",
        type: "choice",
        choices: [
          { name: "Combustible", value: "1" },
          { name: "Électricité", value: "2" },
        ],
      },
      {
        id: "sector|energy",
        title: "Montant unitaire en kWh cumac par m² selon l'énergie de chauffage",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "66" },
          { name: "1|2", value: "37" },
          { name: "2|1", value: "43" },
          { name: "2|2", value: "24" },
          { name: "3|1", value: "47" },
          { name: "3|2", value: "27" },
          { name: "4|1", value: "78" },
          { name: "4|2", value: "29" },
          { name: "5|1", value: "54" },
          { name: "5|2", value: "31" },
          { name: "6|1", value: "43" },
          { name: "6|2", value: "24" },
        ],
      },
      {
        id: "area",
        title: "Surface chauffée (m²)",
        type: "number",
      },
      {
        id: "factor",
        title: "Coefficient multiplicateur",
        type: "value",
        value: ":H:1.1:H:0.9:H:0.6",
      },
    ],
    attachments: [],
  },
  {
    name: "Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative",
    id: "BAT-TH-109",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-109.pdf",
    type: "Optimisation",
    fields: [
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Commerce", value: "4" },
          { name: "Hôtellerie / Restauration", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Montant unitaire en kWh cumac/m²",
        type: "multichoice",
        choices: [
          { name: "1", value: "69" },
          { name: "2", value: "43" },
          { name: "3", value: "53" },
          { name: "4", value: "55" },
          { name: "5", value: "82" },
          { name: "6", value: "43" },
        ],
      },
      {
        id: "factor",
        title: "Coefficient multiplicateur",
        type: "value",
        value: ":H:1.1:H:0.9:H:0.6",
      },
      {
        id: "area",
        title: "Surface chauffée (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2024-01-01T01:00:00.000Z",
    history: [
      {
        name: "Optimiseur de relance en chauffage collectif comprenant une fonction auto-adaptative",
        id: "BAT-TH-109",
        formUrl: "https://greendeed.blob.core.windows.net/forms/BAT-TH-109.pdf",
        type: "Optimisation",
        fields: [
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux", value: "1" },
              { name: "Enseignement", value: "2" },
              { name: "Santé", value: "3" },
              { name: "Commerce", value: "4" },
              { name: "Hôtellerie / Restauration", value: "5" },
              { name: "Autres secteurs", value: "6" },
            ],
          },
          {
            id: "sector",
            title: "Montant unitaire en kWh cumac/m²",
            type: "multichoice",
            choices: [
              { name: "1", value: "100" },
              { name: "2", value: "68" },
              { name: "3", value: "84" },
              { name: "4", value: "82" },
              { name: "5", value: "120" },
              { name: "6", value: "68" },
            ],
          },
          {
            id: "factor",
            title: "Coefficient multiplicateur",
            type: "value",
            value: ":H:1.1:H:0.9:H:0.6",
          },
          {
            id: "area",
            title: "Surface chauffée (m²)",
            type: "number",
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Récupérateur de chaleur à condensation",
    id: "BAT-TH-110",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-110.pdf",
    type: "Thermique",
    fields: [
      {
        id: "usage",
        title: "Usage de la chaudière",
        type: "choice",
        choices: [
          { name: "Chauffage", value: "1" },
          { name: "Chauffage et eau chaude sanitaire", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Commerces", value: "4" },
          { name: "Hôtellerie restauration", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "usage",
        title: "Montant en kWh cumac par m² de surface chauffée",
        type: "multichoice",
        choices: [
          { name: "1", value: ":H:150:H:130:H:80" },
          { name: "2", value: ":H:190:H:160:H:120" },
        ],
      },
      {
        id: "usage|sector",
        title: "Facteur correctif",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "1.2" },
          { name: "1|2", value: "0.8" },
          { name: "1|3", value: "1" },
          { name: "1|4", value: "0.9" },
          { name: "1|5", value: "1.4" },
          { name: "1|6", value: "0.8" },
          { name: "2|1", value: "1.1" },
          { name: "2|2", value: "0.7" },
          { name: "2|3", value: "1.1" },
          { name: "2|4", value: "0.8" },
          { name: "2|5", value: "1.6" },
          { name: "2|6", value: "0.7" },
        ],
      },
      {
        id: "area",
        title: "Surface chauffée en m²",
        type: "number",
      },
      {
        id: "rFactor",
        title: "Coefficient R",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Chauffe-eau solaire collectif (France métropolitaine)",
    metDomTom: 1,
    id: "BAT-TH-111",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-111.pdf",
    type: "Thermique",
    fields: [
      { id: "factor", title: "Coefficient multiplicateur", type: "value", value: "0.196" },
      {
        id: "need",
        title: "Besoin annuel en eau chaude sanitaire à produire par l'énergie solaire (kWh / an)",
        type: "number",
      },
      {
        id: "ratio",
        title: "Taux de couverture du chauffe-eau solaire collectif (exprimé en %)",
        type: "number",
      },
    ],
    attachments: ["Certification CSTBat ou SolarKeymark des capteurs solaires", "Étude de dimensionnement préalable"],
  },
  {
    name: "Système de variation électronique de vitesse sur un moteur asynchrone",
    id: "BAT-TH-112",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-112.pdf",
    type: "Moteur",
    fields: [
      {
        id: "application",
        title: "Application",
        type: "choice",
        choices: [
          { name: "Chauffage, pompage", value: "1" },
          { name: "Ventilation, renouvellement d'air", value: "2" },
          { name: "Réfrigération", value: "3" },
          { name: "Climatisation", value: "4" },
          { name: "Autres applications", value: "5" },
        ],
      },
      {
        id: "application",
        title: "Application",
        type: "multichoice",
        choices: [
          { name: "1", value: "9600" },
          { name: "2", value: "11400" },
          { name: "3", value: "3900" },
          { name: "4", value: "990" },
          { name: "5", value: "990" },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale du moteur en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Pompe à chaleur de type air/eau ou eau/eau",
    id: "BAT-TH-113",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-113.pdf",
    type: "Thermique",
    fields: [
      {
        id: "power",
        type: "choice",
        title: "Puissance thermique nominale de la PAC",
        skipValue: true,
        choices: [
          { name: "Inférieure ou égale à 400 kW", value: "1" },
          { name: "Supérieure à 400 kW", value: "2" },
        ],
      },
      {
        id: "efficacity",
        type: "choice",
        title: "Efficacité énergétique saisonnière (ηs)",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          { name: "111% ≤ ηs < 126%", value: "1" },
          { name: "126% ≤ ηs", value: "2" },
        ],
      },
      {
        id: "efficacity",
        title: "Montant en kWh cumac par m² chauffé",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          { name: "1", value: ":H:390:H:320:H:210" },
          { name: "2", value: ":H:470:H:390:H:260" },
        ],
      },
      {
        id: "cop",
        type: "choice",
        title: "COP",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          {
            name: "3.4 ≤ COP < 4",
            value: "1",
          },
          {
            name: "4 ≤ COP",
            value: "2",
          },
        ],
      },
      {
        id: "cop",
        title: "Montant en kWh cumac par m² chauffé",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          { name: "1", value: ":H:380:H:310:H:210" },
          { name: "2", value: ":H:500:H:410:H:270" },
        ],
      },
      {
        id: "area",
        title: "Surface totale chauffée (m²)",
        type: "number",
      },
      {
        id: "factor",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Hôtellerie, restauration", value: "1" },
          { name: "Santé", value: "2" },
          { name: "Enseignement", value: "3" },
          { name: "Bureaux", value: "4" },
          { name: "Commerces", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "factor",
        title: "Facteur correctif",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.7" },
          { name: "2", value: "1.1" },
          { name: "3", value: "0.8" },
          { name: "4", value: "1.2" },
          { name: "5", value: "0.9" },
          { name: "6", value: "0.7" },
        ],
      },
      {
        id: "rFactor",
        title: "Facteur R",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2024-01-01T01:00:00.000Z",
    history: [
      {
        name: "Pompe à chaleur de type air/eau ou eau/eau",
        id: "BAT-TH-113",
        formUrl:
          "https://www.ecologie.gouv.fr/sites/default/files/Fiche%20BAT-TH-113%20%C2%AB%20Pompe%20%C3%A0%20chaleur%20de%20type%20air-eau%20ou%20eau-eau%20%C2%BB.pdf",
        type: "Thermique",
        fields: [
          {
            id: "power",
            type: "choice",
            title: "Puissance thermique nominale de la PAC",
            skipValue: true,
            choices: [
              { name: "Inférieure ou égale à 400 kW", value: "1" },
              { name: "Supérieure à 400 kW", value: "2" },
            ],
          },
          {
            id: "efficacity",
            type: "choice",
            title: "Efficacité énergétique saisonnière (ηs)",
            showIf: {
              fieldId: "power",
              fieldValues: ["1"],
            },
            choices: [
              { name: "111% ≤ ηs < 126%", value: "1" },
              { name: "126% ≤ ηs", value: "2" },
            ],
          },
          {
            id: "efficacity",
            title: "Montant en kWh cumac par m² chauffé",
            type: "multichoice",
            showIf: {
              fieldId: "power",
              fieldValues: ["1"],
            },
            choices: [
              { name: "1", value: ":H:390:H:320:H:210" },
              { name: "2", value: ":H:470:H:390:H:260" },
            ],
          },
          {
            id: "cop",
            type: "choice",
            title: "COP",
            showIf: {
              fieldId: "power",
              fieldValues: ["2"],
            },
            choices: [
              {
                name: "3.4 ≤ COP < 4",
                value: "1",
              },
              {
                name: "4 ≤ COP",
                value: "2",
              },
            ],
          },
          {
            id: "area",
            title: "Surface totale chauffée (m²)",
            type: "number",
          },
          {
            id: "factor",
            title: "Secteur",
            type: "choice",
            choices: [
              { name: "Hôtellerie, restauration", value: "1" },
              { name: "Santé", value: "2" },
              { name: "Enseignement", value: "3" },
              { name: "Bureaux", value: "4" },
              { name: "Commerces", value: "5" },
              { name: "Autres", value: "6" },
            ],
          },
          {
            id: "factor",
            title: "Facteur correctif",
            type: "multichoice",
            choices: [
              { name: "1", value: "0.7" },
              { name: "2", value: "1.1" },
              { name: "3", value: "0.8" },
              { name: "4", value: "1.2" },
              { name: "5", value: "0.9" },
              { name: "6", value: "0.7" },
            ],
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Climatiseur performant (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-TH-115",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-115.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "sector",
        title: "Branche d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Commerce", value: "3" },
          { name: "Hôtellerie - restauration", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "efficiency",
        title: "Classe d'efficacité énergétique de l'appareil",
        type: "choice",
        choices: [
          { name: "A", value: "1" },
          { name: "A+", value: "2" },
          { name: "A++", value: "3" },
          { name: "A+++", value: "4" },
        ],
      },
      {
        id: "sector|efficiency",
        title: "Montant en kWh cumac",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "1100" },
          { name: "1|2", value: "2000" },
          { name: "1|3", value: "2700" },
          { name: "1|4", value: "5100" },
          { name: "2|1", value: "900" },
          { name: "2|2", value: "1600" },
          { name: "2|3", value: "2200" },
          { name: "2|4", value: "4100" },
          { name: "3|1", value: "1800" },
          { name: "3|2", value: "3200" },
          { name: "3|3", value: "4400" },
          { name: "3|4", value: "8200" },
          { name: "4|1", value: "1300" },
          { name: "4|2", value: "2300" },
          { name: "4|3", value: "3200" },
          { name: "4|4", value: "5900" },
          { name: "5|1", value: "2000" },
          { name: "5|2", value: "3700" },
          { name: "5|3", value: "5100" },
          { name: "5|4", value: "9500" },
          { name: "6|1", value: "900" },
          { name: "6|2", value: "1600" },
          { name: "6|3", value: "2200" },
          { name: "6|4", value: "4100" },
        ],
      },
      {
        id: "power",
        title: "Puissance frigorifique de l'appareil en kW (ou BTU/h)",
        type: "choice",
        choices: [
          { name: "2.05 (7 000)", value: "0.58" },
          { name: "2.64 (9 000)", value: "0.75" },
          { name: "3.52 (12 000)", value: "1" },
          { name: "4.40 (15 000)", value: "1.25" },
          { name: "5.28 (18 000)", value: "1.5" },
          { name: "6.16 (21 000)", value: "1.75" },
          { name: "7.03 (24 000)", value: "2" },
          { name: "8.21 (28 000)", value: "2.33" },
        ],
      },
    ],
    attachments: [],
  },
  {
    name: "Système de gestion technique du bâtiment pour le chauffage, l'eau chaude sanitaire, le refroidissement/climatisation, l'éclairage et les auxiliaires",
    id: "BAT-TH-116",
    formUrl: "https://gdceeforms.blob.core.windows.net/v62/BAT-TH-116.pdf",
    type: "Thermique",
    fields: [
      {
        id: "class",
        title: "Système de gestion technique du bâtiment installé",
        type: "choice",
        choices: [
          { name: "Classe A", value: "1" },
          { name: "Classe B", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Commerces", value: "3" },
          { name: "Hôtellerie, restauration", value: "4" },
          { name: "Santé", value: "5" },
        ],
      },
      {
        id: "usage",
        title: "Usage considéré",
        type: "choice",
        choices: [
          { name: "Chauffage", value: "1" },
          { name: "Refroidissement Climatisation", value: "2" },
          { name: "ECS", value: "3" },
          { name: "Eclairage", value: "4" },
          { name: "Auxiliaire", value: "5" },
        ],
      },
      {
        id: "class|sector|usage",
        title: "Montant en kWh cumac par m² de surface gérée par le système",
        type: "multichoice",
        choices: [
          { name: "1|1|1", value: "360" },
          { name: "1|1|2", value: "233" },
          { name: "1|1|3", value: "15" },
          { name: "1|1|4", value: "184" },
          { name: "1|1|5", value: "19" },

          { name: "1|2|1", value: "170" },
          { name: "1|2|2", value: "60" },
          { name: "1|2|3", value: "82" },
          { name: "1|2|4", value: "46" },
          { name: "1|2|5", value: "6" },

          { name: "1|3|1", value: "520" },
          { name: "1|3|2", value: "150" },
          { name: "1|3|3", value: "30" },
          { name: "1|3|4", value: "0" },
          { name: "1|3|5", value: "6" },

          { name: "1|4|1", value: "400" },
          { name: "1|4|2", value: "60" },
          { name: "1|4|3", value: "32" },
          { name: "1|4|4", value: "65" },
          { name: "1|4|5", value: "6" },

          { name: "1|5|1", value: "150" },
          { name: "1|5|2", value: "60" },
          { name: "1|5|3", value: "87" },
          { name: "1|5|4", value: "0" },
          { name: "1|5|5", value: "19" },

          { name: "2|1|1", value: "240" },
          { name: "2|1|2", value: "97" },
          { name: "2|1|3", value: "7" },
          { name: "2|1|4", value: "90" },
          { name: "2|1|5", value: "8" },

          { name: "2|2|1", value: "100" },
          { name: "2|2|2", value: "23" },
          { name: "2|2|3", value: "38" },
          { name: "2|2|4", value: "21" },
          { name: "2|2|5", value: "3" },

          { name: "2|3|1", value: "250" },
          { name: "2|3|2", value: "44" },
          { name: "2|3|3", value: "13" },
          { name: "2|3|4", value: "0" },
          { name: "2|3|5", value: "3" },

          { name: "2|4|1", value: "200" },
          { name: "2|4|2", value: "23" },
          { name: "2|4|3", value: "14" },
          { name: "2|4|4", value: "30" },
          { name: "2|4|5", value: "3" },

          { name: "2|5|1", value: "90" },
          { name: "2|5|2", value: "23" },
          { name: "2|5|3", value: "40" },
          { name: "2|5|4", value: "0" },
          { name: "2|5|5", value: "9" },
        ],
      },
      {
        id: "factor",
        title: "Coefficient multiplicateur",
        type: "value",
        value: ":H:1.1:H:0.9:H:0.6",
      },
      {
        id: "area",
        title: "Surface gérée par le système pour l'usage considéré (m²)",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2025-01-01T01:00:00.000Z",
    history: [
      {
        name: "Système de gestion technique du bâtiment pour le chauffage, l'eau chaude sanitaire, le refroidissement/climatisation, l'éclairage et les auxiliaires",
        id: "BAT-TH-116",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-116.pdf",
        type: "Thermique",
        fields: [
          {
            id: "class",
            title: "Système de gestion technique du bâtiment installé",
            type: "choice",
            choices: [
              { name: "Classe A", value: "1" },
              { name: "Classe B", value: "2" },
            ],
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux", value: "1" },
              { name: "Enseignement", value: "2" },
              { name: "Commerces", value: "3" },
              { name: "Hôtellerie, restauration", value: "4" },
              { name: "Santé", value: "5" },
            ],
          },
          {
            id: "usage",
            title: "Usage considéré",
            type: "choice",
            choices: [
              { name: "Chauffage", value: "1" },
              { name: "Refroidissement Climatisation", value: "2" },
              { name: "ECS", value: "3" },
              { name: "Eclairage", value: "4" },
              { name: "Auxiliaire", value: "5" },
            ],
          },
          {
            id: "class|sector|usage",
            title: "Montant en kWh cumac par m² de surface gérée par le système",
            type: "multichoice",
            choices: [
              { name: "1|1|1", value: "400" },
              { name: "1|1|2", value: "260" },
              { name: "1|1|3", value: "16" },
              { name: "1|1|4", value: "190" },
              { name: "1|1|5", value: "19" },
              { name: "1|2|1", value: "200" },
              { name: "1|2|2", value: "71" },
              { name: "1|2|3", value: "89" },
              { name: "1|2|4", value: "49" },
              { name: "1|2|5", value: "8" },
              { name: "1|3|1", value: "560" },
              { name: "1|3|2", value: "160" },
              { name: "1|3|3", value: "32" },
              { name: "1|3|4", value: "23" },
              { name: "1|3|5", value: "8" },
              { name: "1|4|1", value: "420" },
              { name: "1|4|2", value: "71" },
              { name: "1|4|3", value: "34" },
              { name: "1|4|4", value: "74" },
              { name: "1|4|5", value: "8" },
              { name: "1|5|1", value: "200" },
              { name: "1|5|2", value: "71" },
              { name: "1|5|3", value: "95" },
              { name: "1|5|4", value: "12" },
              { name: "1|5|5", value: "28" },
              { name: "1|6|1", value: "200" },
              { name: "1|6|2", value: "71" },
              { name: "1|6|3", value: "16" },
              { name: "1|6|4", value: "12" },
              { name: "1|6|5", value: "8" },
              { name: "2|1|1", value: "300" },
              { name: "2|1|2", value: "130" },
              { name: "2|1|3", value: "8" },
              { name: "2|1|4", value: "100" },
              { name: "2|1|5", value: "10" },
              { name: "2|2|1", value: "120" },
              { name: "2|2|2", value: "35" },
              { name: "2|2|3", value: "45" },
              { name: "2|2|4", value: "24" },
              { name: "2|2|5", value: "5" },
              { name: "2|3|1", value: "300" },
              { name: "2|3|2", value: "66" },
              { name: "2|3|3", value: "3" },
              { name: "2|3|4", value: "23" },
              { name: "2|3|5", value: "5" },
              { name: "2|4|1", value: "230" },
              { name: "2|4|2", value: "35" },
              { name: "2|4|3", value: "17" },
              { name: "2|4|4", value: "40" },
              { name: "2|4|5", value: "5" },
              { name: "2|5|1", value: "140" },
              { name: "2|5|2", value: "35" },
              { name: "2|5|3", value: "48" },
              { name: "2|5|4", value: "12" },
              { name: "2|5|5", value: "18" },
              { name: "2|6|1", value: "120" },
              { name: "2|6|2", value: "35" },
              { name: "2|6|3", value: "3" },
              { name: "2|6|4", value: "12" },
              { name: "2|6|5", value: "5" },
            ],
          },
          {
            id: "factor",
            title: "Coefficient multiplicateur",
            type: "value",
            value: ":H:1.1:H:0.9:H:0.6",
          },
          {
            id: "area",
            title: "Surface gérée par le système pour l'usage considéré (m²)",
            type: "number",
          },
        ],
        attachments: [],
        startDate: "2024-07-01T01:00:00.000Z",
      },
      {
        name: "Système de gestion technique du bâtiment pour le chauffage, l'eau chaude sanitaire, le refroidissement/climatisation, l'éclairage et les auxiliaires",
        id: "BAT-TH-116",
        formUrl:
          "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-116%20vA59-5%20%C3%A0%20compter%20du%2001-01-2024.pdf",
        type: "Thermique",
        fields: [
          {
            id: "class",
            title: "Système de gestion technique du bâtiment installé",
            type: "choice",
            choices: [
              { name: "Classe A", value: "1" },
              { name: "Classe B", value: "2" },
            ],
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux", value: "1" },
              { name: "Enseignement", value: "2" },
              { name: "Commerces", value: "3" },
              { name: "Hôtellerie, restauration", value: "4" },
              { name: "Santé", value: "5" },
            ],
          },
          {
            id: "usage",
            title: "Usage considéré",
            type: "choice",
            choices: [
              { name: "Chauffage", value: "1" },
              { name: "Refroidissement Climatisation", value: "2" },
              { name: "ECS", value: "3" },
              { name: "Eclairage", value: "4" },
              { name: "Auxiliaire", value: "5" },
            ],
          },
          {
            id: "class|sector|usage",
            title: "Montant en kWh cumac par m² de surface gérée par le système",
            type: "multichoice",
            choices: [
              { name: "1|1|1", value: "400" },
              { name: "1|1|2", value: "260" },
              { name: "1|1|3", value: "16" },
              { name: "1|1|4", value: "190" },
              { name: "1|1|5", value: "19" },
              { name: "1|2|1", value: "200" },
              { name: "1|2|2", value: "71" },
              { name: "1|2|3", value: "89" },
              { name: "1|2|4", value: "49" },
              { name: "1|2|5", value: "8" },
              { name: "1|3|1", value: "560" },
              { name: "1|3|2", value: "160" },
              { name: "1|3|3", value: "32" },
              { name: "1|3|4", value: "23" },
              { name: "1|3|5", value: "8" },
              { name: "1|4|1", value: "420" },
              { name: "1|4|2", value: "71" },
              { name: "1|4|3", value: "34" },
              { name: "1|4|4", value: "74" },
              { name: "1|4|5", value: "8" },
              { name: "1|5|1", value: "200" },
              { name: "1|5|2", value: "71" },
              { name: "1|5|3", value: "95" },
              { name: "1|5|4", value: "12" },
              { name: "1|5|5", value: "28" },
              { name: "1|6|1", value: "200" },
              { name: "1|6|2", value: "71" },
              { name: "1|6|3", value: "16" },
              { name: "1|6|4", value: "12" },
              { name: "1|6|5", value: "8" },
              { name: "2|1|1", value: "300" },
              { name: "2|1|2", value: "130" },
              { name: "2|1|3", value: "8" },
              { name: "2|1|4", value: "100" },
              { name: "2|1|5", value: "10" },
              { name: "2|2|1", value: "120" },
              { name: "2|2|2", value: "35" },
              { name: "2|2|3", value: "45" },
              { name: "2|2|4", value: "24" },
              { name: "2|2|5", value: "5" },
              { name: "2|3|1", value: "300" },
              { name: "2|3|2", value: "66" },
              { name: "2|3|3", value: "3" },
              { name: "2|3|4", value: "23" },
              { name: "2|3|5", value: "5" },
              { name: "2|4|1", value: "230" },
              { name: "2|4|2", value: "35" },
              { name: "2|4|3", value: "17" },
              { name: "2|4|4", value: "40" },
              { name: "2|4|5", value: "5" },
              { name: "2|5|1", value: "140" },
              { name: "2|5|2", value: "35" },
              { name: "2|5|3", value: "48" },
              { name: "2|5|4", value: "12" },
              { name: "2|5|5", value: "18" },
              { name: "2|6|1", value: "120" },
              { name: "2|6|2", value: "35" },
              { name: "2|6|3", value: "3" },
              { name: "2|6|4", value: "12" },
              { name: "2|6|5", value: "5" },
            ],
          },
          {
            id: "factor",
            title: "Coefficient multiplicateur",
            type: "value",
            value: ":H:1.1:H:0.9:H:0.6",
          },
          {
            id: "area",
            title: "Surface gérée par le système pour l'usage considéré (m²)",
            type: "number",
          },
          /* BACS bonification is shot down on 2024-06-30 */
          {
            id: "type",
            title: "Type d'opération",
            type: "choice",
            choices: [
              { name: "Acquisition d'un nouveau système de gestion technique du bâtiment", value: "2" },
              { name: "Amélioration d'un système existant de gestion technique du bâtiment", value: "1.5" },
            ],
          },
        ],
        attachments: [],
        startDate: "2024-01-01T01:00:00.000Z",
      },
      {
        name: "Système de gestion technique du bâtiment pour le chauffage, l'eau chaude sanitaire, le refroidissement/climatisation, l'éclairage et les auxiliaires",
        id: "BAT-TH-116",
        formUrl:
          "https://www.ecologie.gouv.fr/sites/default/files/BAT-TH-116%20v%20A38-3%20%C3%A0%20compter%20du%2001-11-2021.pdf",
        type: "Thermique",
        fields: [
          {
            id: "class",
            title: "Système de gestion technique du bâtiment installé",
            type: "choice",
            choices: [
              { name: "Classe A", value: "1" },
              { name: "Classe B", value: "2" },
            ],
          },
          {
            id: "sector",
            title: "Secteur d'activité",
            type: "choice",
            choices: [
              { name: "Bureaux", value: "1" },
              { name: "Enseignement", value: "2" },
              { name: "Commerces", value: "3" },
              { name: "Hôtellerie, restauration", value: "4" },
              { name: "Santé", value: "5" },
              { name: "Autres Secteurs", value: "6" },
            ],
          },
          {
            id: "usage",
            title: "Usage considéré",
            type: "choice",
            choices: [
              { name: "Chauffage", value: "1" },
              { name: "Refroidissement Climatisation", value: "2" },
              { name: "ECS", value: "3" },
              { name: "Eclairage", value: "4" },
              { name: "Auxiliaire", value: "5" },
            ],
          },
          {
            id: "class|sector|usage",
            title: "Montant en kWh cumac par m² de surface gérée par le système",
            type: "multichoice",
            choices: [
              { name: "1|1|1", value: "400" },
              { name: "1|1|2", value: "260" },
              { name: "1|1|3", value: "16" },
              { name: "1|1|4", value: "190" },
              { name: "1|1|5", value: "19" },
              { name: "1|2|1", value: "200" },
              { name: "1|2|2", value: "71" },
              { name: "1|2|3", value: "89" },
              { name: "1|2|4", value: "49" },
              { name: "1|2|5", value: "8" },
              { name: "1|3|1", value: "560" },
              { name: "1|3|2", value: "160" },
              { name: "1|3|3", value: "32" },
              { name: "1|3|4", value: "23" },
              { name: "1|3|5", value: "8" },
              { name: "1|4|1", value: "420" },
              { name: "1|4|2", value: "71" },
              { name: "1|4|3", value: "34" },
              { name: "1|4|4", value: "74" },
              { name: "1|4|5", value: "8" },
              { name: "1|5|1", value: "200" },
              { name: "1|5|2", value: "71" },
              { name: "1|5|3", value: "95" },
              { name: "1|5|4", value: "12" },
              { name: "1|5|5", value: "28" },
              { name: "1|6|1", value: "200" },
              { name: "1|6|2", value: "71" },
              { name: "1|6|3", value: "16" },
              { name: "1|6|4", value: "12" },
              { name: "1|6|5", value: "8" },
              { name: "2|1|1", value: "300" },
              { name: "2|1|2", value: "130" },
              { name: "2|1|3", value: "8" },
              { name: "2|1|4", value: "100" },
              { name: "2|1|5", value: "10" },
              { name: "2|2|1", value: "120" },
              { name: "2|2|2", value: "35" },
              { name: "2|2|3", value: "45" },
              { name: "2|2|4", value: "24" },
              { name: "2|2|5", value: "5" },
              { name: "2|3|1", value: "300" },
              { name: "2|3|2", value: "66" },
              { name: "2|3|3", value: "3" },
              { name: "2|3|4", value: "23" },
              { name: "2|3|5", value: "5" },
              { name: "2|4|1", value: "230" },
              { name: "2|4|2", value: "35" },
              { name: "2|4|3", value: "17" },
              { name: "2|4|4", value: "40" },
              { name: "2|4|5", value: "5" },
              { name: "2|5|1", value: "140" },
              { name: "2|5|2", value: "35" },
              { name: "2|5|3", value: "48" },
              { name: "2|5|4", value: "12" },
              { name: "2|5|5", value: "18" },
              { name: "2|6|1", value: "120" },
              { name: "2|6|2", value: "35" },
              { name: "2|6|3", value: "3" },
              { name: "2|6|4", value: "12" },
              { name: "2|6|5", value: "5" },
            ],
          },
          {
            id: "factor",
            title: "Coefficient multiplicateur",
            type: "value",
            value: ":H:1.1:H:0.9:H:0.6",
          },
          {
            id: "area",
            title: "Surface gérée par le système pour l'usage considéré (m²)",
            type: "number",
          },
          {
            id: "type",
            title: "Type d'opération",
            type: "choice",
            choices: [
              { name: "Acquisition d'un nouveau système de gestion technique du bâtiment", value: "2" },
              { name: "Amélioration d'un système existant de gestion technique du bâtiment", value: "1.5" },
            ],
          },
        ],
        attachments: [],
      },
    ],
  },
  {
    name: "Chauffe-eau solaire (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-TH-121",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-121.pdf",
    type: "Thermique",
    fields: [
      {
        id: "geoZone",
        title: "Zones géographiques",
        type: "choice",
        choices: [{ name: "Réunion, Mayotte, Guyane, Guadeloupe, Martinique", value: "1" }],
      },
      {
        id: "type",
        title: "Types de chauffe-eau solaire",
        type: "choice",
        choices: [
          { name: "CESI et CES collectif à appoint individualisé", value: "1" },
          { name: "CES collectif à appoint centralisé", value: "2" },
        ],
      },
      {
        id: "geoZone|type",
        title: "Coefficient multiplicateur",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "0.148" },
          { name: "1|2", value: "0.086" },
        ],
      },
      {
        id: "need",
        title: "Besoin annuel en eau chaude sanitaire à produire par l'énergie solaire (kWh / an)",
        type: "number",
      },
      {
        id: "ratio",
        title: "Taux de couverture solaire de l'installation déterminé en %",
        type: "number",
        minValue: 50,
        maxValue: 90,
      },
    ],
    attachments: [
      "Avis de certification QB",
      "Étude de dimensionnement préalable",
      "Décision de qualification ou de certification du professionnel",
    ],
  },
  {
    name: "Programmateur d'intermittence pour la climatisation (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-TH-122",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-122.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Commerce", value: "2" },
          { name: "Hôtellerie", value: "3" },
          { name: "Enseignement", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres secteurs", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Montant en kWh cumac par m²",
        type: "multichoice",
        choices: [
          { name: "1", value: "560" },
          { name: "2", value: "180" },
          { name: "3", value: "340" },
          { name: "4", value: "460" },
          { name: "5", value: "210" },
          { name: "6", value: "180" },
        ],
      },
      {
        id: "area",
        title: "Surface climatisée (m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Ventilation mécanique simple flux à débit d'air constant ou modulé",
    id: "BAT-TH-125",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-125.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "type",
        title: "Type de ventilation mécanique simple flux",
        type: "choice",
        choices: [
          { name: "Modulée proportionnelle", value: "1" },
          { name: "Modulée à détection de présence", value: "2" },
          { name: "Débit d'air constant", value: "3" },
        ],
      },
      {
        id: "type",
        title: "Montant en kWh cumac par m² de surface ventilée",
        type: "multichoice",
        choices: [
          { name: "1", value: ":H:770:H:630:H:420" },
          { name: "2", value: ":H:690:H:560:H:380" },
          { name: "3", value: ":H:400:H:330:H:220" },
        ],
      },
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Restauration", value: "3" },
          { name: "Autres locaux", value: "4" },
        ],
      },
      {
        id: "type|sector",
        title: "Facteur multiplicatif",
        type: "multichoice",
        choices: [
          { name: "1|1", value: "0.48" },
          { name: "1|2", value: "1" },
          { name: "1|3", value: "0.59" },
          { name: "1|4", value: "0.54" },
          { name: "2|1", value: "0.4" },
          { name: "2|2", value: "1" },
          { name: "2|3", value: "0.45" },
          { name: "2|4", value: "0.51" },
          { name: "3|1", value: "0.4" },
          { name: "3|2", value: "1" },
          { name: "3|3", value: "0.53" },
          { name: "3|4", value: "0.58" },
        ],
      },
      {
        id: "area",
        title: "Surface ventilée (m²)",
        type: "number",
      },
    ],
    attachments: ["Avis technique de la CCFAT"],
    attachmentsShowIf: [
      {
        attachmentId: 0,
        condition: {
          fieldId: "type",
          fieldValues: ["1", "2"],
        },
      },
    ],
  },
  {
    name: "Ventilation mécanique double flux avec échangeur à débit d'air constant ou modulé",
    id: "BAT-TH-126",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-126.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "type",
        title: "Type de ventilation mécanique double flux",
        type: "choice",
        choices: [
          { name: "Modulée proportionnelle", value: "1" },
          { name: "Modulée à détection de présence", value: "2" },
          { name: "Débit d'air constant", value: "3" },
        ],
      },
      {
        id: "type",
        title: "Montant en kWh cumac par m² de surface ventilée",
        type: "multichoice",
        choices: [
          { name: "1", value: ":H:1000:H:830:H:560" },
          { name: "2", value: ":H:970:H:800:H:530" },
          { name: "3", value: ":H:850:H:700:H:460" },
        ],
      },
      {
        id: "factor",
        title: "Secteur",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["1"] },
        choices: [
          { name: "Bureaux", value: "0.53" },
          { name: "Enseignement", value: "1" },
          { name: "Restauration", value: "0.68" },
          { name: "Etablissement sportif", value: "0.22" },
          { name: "Autres locaux", value: "0.71" },
          { name: "Salles d'un volume supérieur à 250 m³ (voir fiche)", value: "1.88" },
        ],
      },
      {
        id: "factor",
        title: "Secteur",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["2"] },
        choices: [
          { name: "Bureaux", value: "0.51" },
          { name: "Enseignement", value: "1" },
          { name: "Restauration", value: "0.63" },
          { name: "Etablissement sportif", value: "0.17" },
          { name: "Autres locaux", value: "0.71" },
        ],
      },
      {
        id: "factor",
        title: "Secteur",
        type: "choice",
        showIf: { fieldId: "type", fieldValues: ["3"] },
        choices: [
          { name: "Bureaux", value: "0.48" },
          { name: "Enseignement", value: "1" },
          { name: "Restauration", value: "0.61" },
          { name: "Etablissement sportif", value: "0.52" },
          { name: "Autres locaux", value: "0.71" },
          { name: "Salles d'un volume supérieur à 250 m³ (voir fiche)", value: "1.44" },
        ],
      },
      {
        id: "area",
        title: "Surface ventilée (m²)",
        type: "number",
      },
    ],
    attachments: ["Avis technique de la CCFAT"],
    attachmentsShowIf: [
      {
        attachmentId: 0,
        condition: {
          fieldId: "type",
          fieldValues: ["1", "2"],
        },
      },
    ],
  },
  {
    name: "Raccordement d'un bâtiment tertiaire à un réseau de chaleur",
    id: "BAT-TH-127",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-127.pdf",
    type: "Thermique",
    fields: [
      {
        id: "power",
        title: "Puissance souscrite",
        type: "choice",
        choices: [
          { name: "Inférieure ou égale à 400 kW", value: "1" },
          { name: "Strictement supérieure à 400 kW", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Commerces", value: "4" },
          { name: "Hôtellerie / Restauration", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "usage",
        title: "Usage de la chaleur du réseau",
        type: "choice",
        choices: [
          { name: "Chauffage", value: "1" },
          { name: "Chauffage et eau chaude sanitaire", value: "2" },
        ],
      },
      {
        id: "power|sector|usage",
        title: "Montant en kWh cumac par m² de surface chauffée",
        type: "multichoice",
        choices: [
          { name: "1|1|1", value: "480" },
          { name: "1|1|2", value: "510" },
          { name: "1|2|1", value: "310" },
          { name: "1|2|2", value: "360" },
          { name: "1|3|1", value: "400" },
          { name: "1|3|2", value: "540" },
          { name: "1|4|1", value: "370" },
          { name: "1|4|2", value: "420" },
          { name: "1|5|1", value: "540" },
          { name: "1|5|2", value: "680" },
          { name: "1|6|1", value: "310" },
          { name: "1|6|2", value: "330" },
          { name: "2|1|1", value: "370" },
          { name: "2|1|2", value: "390" },
          { name: "2|2|1", value: "240" },
          { name: "2|2|2", value: "270" },
          { name: "2|3|1", value: "310" },
          { name: "2|3|2", value: "410" },
          { name: "2|4|1", value: "280" },
          { name: "2|4|2", value: "320" },
          { name: "2|5|1", value: "410" },
          { name: "2|5|2", value: "520" },
          { name: "2|6|1", value: "240" },
          { name: "2|6|2", value: "260" },
        ],
      },
      {
        id: "factor",
        title: "Coefficient multiplicateur",
        type: "value",
        value: ":H:1.1:H:0.9:H:0.6",
      },
      {
        id: "area",
        title: "Surface chauffée (en m²)",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante (France métropolitaine)",
    metDomTom: 1,
    id: "BAT-TH-134",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-134.pdf",
    type: "Froid",
    fields: [
      {
        id: "usage",
        title: "Usage",
        type: "choice",
        choices: [
          {
            name: "Climatisation de confort d'un local (hors datacenter)",
            value: "1",
          },
          {
            name: "Climatisation d'un datacenter",
            value: "2",
          },
          {
            name: "Autres applications de type réfrigération ou conditionnement d'ambiance hors confort des occupants",
            value: "3",
          },
        ],
      },
      {
        id: "type",
        title: "Type de condensation",
        type: "choice",
        choices: [
          { name: "Condensation par rapport à l'atmosphère", value: "1" },
          { name: "Condensation à eau seule", value: "2" },
        ],
      },
      {
        id: "usage|type",
        title: "Montant en kWh cumac par kW",
        type: "multichoice",
        choices: [
          { name: "1|1", value: ":H:2000:H:1800:H:1600" },
          { name: "1|2", value: ":H:670:H:480:H:290" },
          { name: "2|1", value: ":H:22800:H:20500:H:20200" },
          { name: "2|2", value: ":H:14500:H:13900:H:11300" },
          { name: "3|1", value: ":H:19100:H:17000:H:16400" },
          { name: "3|2", value: ":H:13400:H:12800:H:10500" },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale totale du groupe de production de froid en kW",
        type: "number",
      },
    ],
    attachments: ["Étude technique préalable"],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante (France métropolitaine)",
        metDomTom: 1,
        id: "BAT-TH-134",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-134.pdf",
        type: "Froid",
        fields: [
          {
            id: "usage",
            title: "Usage",
            type: "choice",
            choices: [
              {
                name: "Climatisation de confort d'un local (hors datacenter)",
                value: "1",
              },
              {
                name: "Climatisation d'un datacenter",
                value: "2",
              },
              {
                name: "Autres applications de type réfrigération ou conditionnement d'ambiance hors confort des occupants",
                value: "3",
              },
            ],
          },
          {
            id: "type",
            title: "Type de condensation",
            type: "choice",
            choices: [
              { name: "Condensation par rapport à l'atmosphère", value: "1" },
              { name: "Condensation à eau seule", value: "2" },
            ],
          },
          {
            id: "usage|type",
            title: "Montant en kWh cumac par kW",
            type: "multichoice",
            choices: [
              { name: "1|1", value: ":H:2000:H:1800:H:1600" },
              { name: "1|2", value: ":H:670:H:480:H:290" },
              { name: "2|1", value: ":H:22800:H:20500:H:20200" },
              { name: "2|2", value: ":H:14500:H:13900:H:11300" },
              { name: "3|1", value: ":H:19100:H:17000:H:16400" },
              { name: "3|2", value: ":H:13400:H:12800:H:10500" },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale du ou des compresseur(s) en kW",
            type: "number",
          },
        ],
        attachments: ["Étude technique préalable"],
      },
    ],
  },
  {
    name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante (France d'outre-mer)",
    metDomTom: 2,
    id: "BAT-TH-135",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-135.pdf",
    type: "Froid",
    fields: [
      {
        id: "usage",
        title: "Usage",
        type: "choice",
        choices: [
          {
            name: "Réfrigération",
            value: "2700",
          },
          {
            name: "Climatisation hors datacenter",
            value: "2500",
          },
          {
            name: "Climatisation datacenter",
            value: "4700",
          },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale totale du groupe de production de froid en kW",
        type: "number",
      },
    ],
    attachments: ["Étude technique préalable"],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de régulation sur un groupe de production de froid permettant d'avoir une haute pression flottante (France d'outre-mer)",
        metDomTom: 2,
        id: "BAT-TH-135",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-135.pdf",
        type: "Froid",
        fields: [
          {
            id: "usage",
            title: "Usage",
            type: "choice",
            choices: [
              {
                name: "Réfrigération",
                value: "2700",
              },
              {
                name: "Climatisation hors datacenter",
                value: "2500",
              },
              {
                name: "Climatisation datacenter",
                value: "4700",
              },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale du ou des compresseur(s) en kW",
            type: "number",
          },
        ],
        attachments: ["Étude technique préalable"],
      },
    ],
  },
  {
    name: "Système de récupération de chaleur sur un groupe de production de froid",
    id: "BAT-TH-139",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-139.pdf",
    type: "Froid",
    fields: [
      {
        id: "useDuration",
        title: "Durée annuelle d'utilisation de la chaleur récupérée (D), en heures",
        type: "number",
      },
      {
        id: "factor",
        title: "Facteur multiplicatif",
        type: "value",
        value: "9.9",
      },
      {
        id: "power",
        title: "Puissance thermique récupérée en kW",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Pompe à chaleur à absorption de type air/eau ou eau/eau",
    id: "BAT-TH-140",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-140.pdf",
    type: "Thermique",
    fields: [
      {
        id: "power",
        type: "choice",
        title: "Puissance thermique nominale de la PAC",
        skipValue: true,
        choices: [
          { name: "Inférieure ou égale à 400 kW", value: "1" },
          { name: "Supérieure à 400 kW", value: "2" },
        ],
      },
      {
        id: "usage",
        title: "Usage",
        type: "choice",
        choices: [
          { name: "Chauffage", value: "1" },
          { name: "Chauffage et ECS", value: "2" },
        ],
      },
      {
        id: "efficacity",
        type: "choice",
        title: "Efficacité énergétique saisonnière (ηs)",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          { name: "111% ≤ ηs < 126%", value: "1" },
          { name: "126% ≤ ηs", value: "2" },
        ],
      },
      {
        id: "efficacity|usage",
        title: "Montant en kWh cumac par m² chauffé",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          { name: "1|1", value: ":H:600:H:490:H:330" },
          { name: "1|2", value: ":H:710:H:580:H:390" },
          { name: "2|1", value: ":H:700:H:570:H:380" },
          { name: "2|2", value: ":H:830:H:680:H:450" },
        ],
      },
      {
        id: "cop",
        type: "choice",
        title: "COP",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          {
            name: "1.3 ≤ COP < 1.6",
            value: "1",
          },
          {
            name: "1.6 ≤ COP",
            value: "2",
          },
        ],
      },
      {
        id: "cop|usage",
        title: "Montant en kWh cumac par m² chauffé",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          { name: "1|1", value: ":H:730:H:600:H:400" },
          { name: "1|2", value: ":H:870:H:710:H:470" },
          { name: "2|1", value: ":H:930:H:760:H:500" },
          { name: "2|2", value: ":H:1100:H:900:H:600" },
        ],
      },
      {
        id: "area",
        title: "Surface totale chauffée (m²)",
        type: "number",
      },
      {
        id: "factor",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Commerces", value: "3" },
          { name: "Hôtellerie Restauration", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "factor",
        title: "Facteur correctif",
        type: "multichoice",
        choices: [
          { name: "1", value: "1" },
          { name: "2", value: "0.7" },
          { name: "3", value: "0.9" },
          { name: "4", value: "1.4" },
          { name: "5", value: "1.1" },
          { name: "6", value: "0.7" },
        ],
      },
      {
        id: "rFactor",
        title: "Facteur R",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Pompe à chaleur à moteur gaz de type air/eau",
    id: "BAT-TH-141",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-141.pdf",
    type: "Thermique",
    fields: [
      {
        id: "power",
        type: "choice",
        title: "Puissance thermique nominale de la PAC",
        skipValue: true,
        choices: [
          { name: "Inférieure ou égale à 400 kW", value: "1" },
          { name: "Supérieure à 400 kW", value: "2" },
        ],
      },
      {
        id: "usage",
        title: "Usage",
        type: "choice",
        choices: [
          { name: "Chauffage", value: "1" },
          { name: "Chauffage et ECS", value: "2" },
        ],
      },
      {
        id: "efficacity",
        type: "choice",
        title: "Efficacité énergétique saisonnière (ηs)",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          { name: "111% ≤ ηs < 126%", value: "1" },
          { name: "126% ≤ ηs", value: "2" },
        ],
      },
      {
        id: "efficacity|usage",
        title: "Montant en kWh cumac par m² chauffé",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["1"],
        },
        choices: [
          { name: "1|1", value: ":H:570:H:460:H:310" },
          { name: "1|2", value: ":H:670:H:550:H:370" },
          { name: "2|1", value: ":H:660:H:540:H:360" },
          { name: "2|2", value: ":H:780:H:640:H:430" },
        ],
      },
      {
        id: "cop",
        type: "choice",
        title: "COP",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          {
            name: "1.3 ≤ COP < 1.6",
            value: "1",
          },
          {
            name: "1.6 ≤ COP",
            value: "2",
          },
        ],
      },
      {
        id: "cop|usage",
        title: "Montant en kWh cumac par m² chauffé",
        type: "multichoice",
        showIf: {
          fieldId: "power",
          fieldValues: ["2"],
        },
        choices: [
          { name: "1|1", value: ":H:690:H:560:H:370" },
          { name: "1|2", value: ":H:820:H:670:H:440" },
          { name: "2|1", value: ":H:870:H:710:H:470" },
          { name: "2|2", value: ":H:1000:H:850:H:560" },
        ],
      },
      {
        id: "area",
        title: "Surface totale chauffée (m²)",
        type: "number",
      },
      {
        id: "factor",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Enseignement", value: "2" },
          { name: "Commerces", value: "3" },
          { name: "Hôtellerie Restauration", value: "4" },
          { name: "Santé", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "factor",
        title: "Facteur correctif",
        type: "multichoice",
        choices: [
          { name: "1", value: "1" },
          { name: "2", value: "0.7" },
          { name: "3", value: "0.9" },
          { name: "4", value: "1.4" },
          { name: "5", value: "1.1" },
          { name: "6", value: "0.7" },
        ],
      },
      {
        id: "rFactor",
        title: "Facteur R",
        type: "number",
      },
    ],
    attachments: ["Document issu du fabricant indiquant la valeur du COP"],
  },
  {
    name: "Système de déstratification d'air",
    id: "BAT-TH-142",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-142.pdf",
    type: "Bâtiment",
    fields: [
      {
        id: "purpose",
        title: "Utilisation du bâtiment",
        type: "choice",
        choices: [
          { name: "Activités sportives ou transports", value: "1" },
          { name: "Commerce, spectacles ou conférences, loisirs ou lieux de culte", value: "2" },
        ],
      },
      {
        id: "type",
        title: "Type de chauffage",
        type: "choice",
        choices: [
          { name: "Système convectif", value: "1" },
          { name: "Système radiatif", value: "2" },
        ],
      },
      {
        id: "height",
        title: "Hauteur du local en mètres",
        type: "choice",
        choices: [
          { name: "5 ≤ h < 7", value: "1" },
          { name: "7 ≤ h < 10", value: "2" },
          { name: "10 ≤ h < 15", value: "3" },
          { name: "15 ≤ h < 20", value: "4" },
          { name: "h ≥ 20", value: "5" },
        ],
      },
      {
        id: "purpose|type|height",
        title: "Montant de certificats en kWh cumac par kW",
        type: "multichoice",
        choices: [
          { name: "1|1|1", value: ":H:900:H:1000:H:1300" },
          { name: "1|1|2", value: ":H:2700:H:3100:H:4000" },
          { name: "1|1|3", value: ":H:5100:H:5700:H:7000" },
          { name: "1|1|4", value: ":H:7200:H:7800:H:9100" },
          { name: "1|1|5", value: ":H:8000:H:8600:H:9900" },
          { name: "1|2|1", value: ":H:320:H:350:H:460" },
          { name: "1|2|2", value: ":H:950:H:1090:H:1400" },
          { name: "1|2|3", value: ":H:1800:H:2000:H:2500" },
          { name: "1|2|4", value: ":H:2500:H:2700:H:3200" },
          { name: "1|2|5", value: ":H:2800:H:3000:H:3500" },
          { name: "2|1|1", value: ":H:600:H:700:H:900" },
          { name: "2|1|2", value: ":H:2000:H:2200:H:2800" },
          { name: "2|1|3", value: ":H:4000:H:4400:H:5200" },
          { name: "2|1|4", value: ":H:5800:H:6300:H:7200" },
          { name: "2|1|5", value: ":H:6700:H:7100:H:8000" },
          { name: "2|2|1", value: ":H:210:H:250:H:320" },
          { name: "2|2|2", value: ":H:700:H:770:H:980" },
          { name: "2|2|3", value: ":H:1400:H:1500:H:1800" },
          { name: "2|2|4", value: ":H:2000:H:2200:H:2500" },
          { name: "2|2|5", value: ":H:2300:H:2500:H:2800" },
        ],
      },
      {
        id: "power",
        title: "Puissance nominale du système de chauffage du local (en kW)",
        type: "number",
      },
    ],
    attachments: ["Note de dimensionnement"],
  },
  {
    name: "Ventilo-convecteurs haute performance",
    id: "BAT-TH-143",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-143.pdf",
    type: "Thermique",
    fields: [
      {
        id: "heatArea",
        title: "Surface totale chauffée (m²)",
        type: "number",
        skipValue: true,
      },
      {
        id: "chillArea",
        title: "Surface totale rafraîchie (m²)",
        type: "number",
        skipValue: true,
      },
      {
        id: "usage",
        title: "Usage",
        type: "choice",
        skipValue: true,
        choices: [
          { name: "Santé avec hébergement", value: "1" },
          { name: "Hôtels et autres hébergements", value: "2" },
          { name: "Santé sans hébergement", value: "3" },
          { name: "Bureaux, restauration, commerces", value: "4" },
          { name: "Autres secteurs", value: "5" },
        ],
      },
      {
        id: "formula",
        title: "Formule",
        type: "formula",
        value: "BATTH143",
      },
    ],
    attachments: [],
  },
  {
    name: "Système de régulation sur un groupe de production de froid permettant d'avoir une basse pression flottante (France métropolitaine)",
    metDomTom: 1,
    id: "BAT-TH-145",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-145.pdf",
    type: "Froid",
    fields: [
      {
        id: "type",
        title: "Application du groupe de production de froid",
        type: "choice",
        choices: [
          { name: "Réfrigération ou conditionnement d'ambiance hors confort", value: "3600" },
          { name: "Climatisation de confort d'un local", value: "310" },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale totale du groupe de production de froid en kW",
        type: "number",
      },
    ],
    attachments: ["Étude technique préalable"],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Système de régulation sur un groupe de production de froid permettant d'avoir une basse pression flottante (France métropolitaine)",
        metDomTom: 1,
        id: "BAT-TH-145",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-145.pdf",
        type: "Froid",
        fields: [
          {
            id: "type",
            title: "Application du groupe de production de froid",
            type: "choice",
            choices: [
              { name: "Réfrigération ou conditionnement d'ambiance hors confort", value: "3600" },
              { name: "Climatisation de confort d'un local", value: "310" },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale du ou des compresseur(s) en kW",
            type: "number",
          },
        ],
        attachments: ["Étude technique préalable"],
      },
    ],
  },
  {
    name: "Isolation d'un réseau hydraulique de chauffage ou d'eau chaude sanitaire (France métropolitaine)",
    metDomTom: 1,
    id: "BAT-TH-146",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-146.pdf",
    type: "Thermique",
    fields: [
      {
        id: "geo",
        title: "Montant en kWh cumac par mètre de réseau isolé",
        type: "value",
        value: ":H:4300:H:4000:H:3600",
      },
      {
        id: "length",
        title: "Longueur isolée de réseau de chauffage ou d'ECS hors du volume chauffé (en m)",
        type: "number",
      },
    ],
    attachments: ["Justification de l'accréditation de l'organisme d'inspection"],
  },
  {
    name: "Système de confinement des allées froides et allées chaudes dans un Data Center",
    id: "BAT-TH-153",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-153.pdf",
    type: "Froid",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par (kW.°C)",
        type: "value",
        value: "1500",
      },
      {
        id: "deltaTemp",
        title: "Gain sur les températures de consigne (°C)",
        type: "number",
      },
      {
        id: "power",
        title: "Puissance électrique nominale du groupe de production de froid (ou batteries froides) (kW)",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Récupération instantanée de chaleur sur eaux grises",
    id: "BAT-TH-154",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-154.pdf",
    type: "Thermique",
    fields: [
      {
        id: "flow",
        title: "Type de débits",
        type: "choice",
        choices: [
          { name: "Débits inégaux", value: "1" },
          { name: "Débits égaux", value: "2" },
        ],
      },
      {
        id: "usage",
        title: "Usage du Bâtiment",
        type: "choice",
        showIf: { fieldId: "flow", fieldValues: ["1"] },
        choices: [
          { name: "Hôtellerie", value: "1" },
          { name: "Établissement sportif", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Terrain de camping", value: "4" },
          { name: "Salon de coiffure", value: "5" },
        ],
      },
      {
        id: "flow|usage",
        title: "Volume unitaire en kWh cumac",
        type: "multichoice",
        showIf: { fieldId: "flow", fieldValues: ["1"] },
        choices: [
          { name: "1|1", value: ":H:13000:H:12200:H:10900" },
          { name: "1|2", value: ":H:18100:H:17100:H:15300" },
          { name: "1|3", value: ":H:6400:H:6000:H:5300" },
          { name: "1|4", value: ":H:72200:H:67900:H:60900" },
          { name: "1|5", value: ":H:33300:H:27100:H:17900" },
        ],
      },
      {
        id: "usage",
        title: "Usage du Bâtiment",
        type: "choice",
        showIf: { fieldId: "flow", fieldValues: ["2"] },
        choices: [
          { name: "Hôtellerie", value: "1" },
          { name: "Établissement sportif", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Terrain de camping", value: "4" },
          { name: "Salon de coiffure", value: "5" },
          { name: "Piscines recevant du public (renouvellement bassin + lavages des filtres)", value: "6" },
          { name: "Piscines recevant du public (renouvellement bassin seul)", value: "7" },
        ],
      },
      {
        id: "flow|usage",
        title: "Volume unitaire en kWh cumac",
        type: "multichoice",
        showIf: { fieldId: "flow", fieldValues: ["2"] },
        choices: [
          { name: "2|1", value: ":H:16500:H:15500:H:13800" },
          { name: "2|2", value: ":H:22900:H:21600:H:19300" },
          { name: "2|3", value: ":H:8100:H:7600:H:6800" },
          { name: "2|4", value: ":H:91400:H:86000:H:77000" },
          { name: "2|5", value: ":H:42100:H:34300:H:22700" },
          { name: "2|6", value: ":H:35:H:32:H:27" },
          { name: "2|7", value: ":H:11:H:10:H:8" },
        ],
      },
      {
        id: "quantity",
        titles: [
          { fieldId: "usage", fieldValue: "1", title: "Nombre de chambres équipées" },
          { fieldId: "usage", fieldValue: "2", title: "Nombre de douches raccordées au système" },
          { fieldId: "usage", fieldValue: "3", title: "Nombre de chambres équipées" },
          { fieldId: "usage", fieldValue: "4", title: "Nombre de douches raccordées au système" },
          { fieldId: "usage", fieldValue: "5", title: "Nombre de salons équipés" },
          { fieldId: "usage", fieldValue: "6", title: "Nombre de baigneurs / an" },
          { fieldId: "usage", fieldValue: "7", title: "Nombre de baigneurs / an" },
        ],
        type: "number",
        showIf: { fieldId: "usage", fieldValues: ["1", "2", "3", "4", "5", "6", "7"] },
      },
      {
        id: "efficacity",
        title: "Efficacité du récupérateur (ε) en % (ex : 0.35 pour 35 %)",
        type: "number",
      },
    ],
    attachments: [
      "Document attestant l'efficacité du système",
      "Copie du carnet sanitaire",
      "Bilan de la fréquentation (nombre de baigneurs)",
    ],
    attachmentsShowIf: [{ attachmentId: 2, condition: { fieldId: "usage", fieldValues: ["6", "7"] } }],
  },
  {
    name: "Isolation de points singuliers d'un réseau",
    id: "BAT-TH-155",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-155.pdf",
    type: "Thermique",
    fields: [
      {
        id: "type",
        title: "Type de points singuliers",
        skipValue: true,
        type: "choice",
        choices: [
          { name: "Points singuliers hors échangeur à plaques", value: "1" },
          { name: "Échangeurs à plaques", value: "2" },
        ],
      },
      {
        id: "temperature",
        title: "Température du fluide caloporteur",
        type: "choice",
        choices: [
          { name: "50°C ≤ T ≤ 120°C", value: "1" },
          { name: "T > 120°C", value: "2" },
        ],
      },
      {
        id: "sector",
        title: "Secteur d'activité",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Santé", value: "2" },
          { name: "Hôtellerie Restauration", value: "3" },
          { name: "Enseignement", value: "4" },
          { name: "Autres secteurs", value: "5" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.35" },
          { name: "2", value: "1" },
          { name: "3", value: "1" },
          { name: "4", value: "0.2" },
          { name: "5", value: "0.2" },
        ],
      },
      {
        id: "diameter",
        title: "Diamètre nominal de la canalisation (mm)",
        type: "choice",
        showIf: {
          fieldId: "type",
          fieldValues: ["1"],
        },
        choices: [
          { name: "20 ≤ DN ≤ 65", value: "1" },
          { name: "65 < DN ≤ 100", value: "2" },
          { name: "100 < DN", value: "3" },
        ],
      },
      {
        id: "diameter|temperature",
        title: "Montant en kWhcumac par housse isolante mise en place",
        type: "multichoice",
        showIf: {
          fieldId: "type",
          fieldValues: ["1"],
        },
        choices: [
          { name: "1|1", value: ":H:11700:H:10500:H:8800" },
          { name: "1|2", value: ":H:12900:H:11600:H:9700" },
          { name: "2|1", value: ":H:25100:H:22700:H:18900" },
          { name: "2|2", value: ":H:27800:H:25100:H:20900" },
          { name: "3|1", value: ":H:40900:H:37000:H:30800" },
          { name: "3|2", value: ":H:45400:H:41000:H:34100" },
        ],
      },
      {
        id: "temperature",
        title: "Montant en kWh cumac par échangeur isolé",
        type: "multichoice",
        showIf: {
          fieldId: "type",
          fieldValues: ["2"],
        },
        choices: [
          { name: "1", value: ":H:77200:H:73500:H:66900" },
          { name: "2", value: ":H:88000:H:83900:H:76300" },
        ],
      },
      {
        id: "quantity",
        titles: [
          { fieldId: "type", fieldValue: "1", title: "Nombre de housses isolantes mises en place" },
          { fieldId: "type", fieldValue: "2", title: "Nombre d'échangeurs à plaques isolés" },
        ],
        type: "number",
        showIf: {
          fieldId: "type",
          fieldValues: ["1", "2"],
        },
      },
    ],
    attachments: ["État récapitulatif", "Justification de l'accréditation de l'organisme d'inspection"],
  },
  {
    name: "Freecooling par eau de refroidissement en substitution d'un groupe froid pour la climatisation",
    id: "BAT-TH-156",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-156.pdf",
    type: "Froid",
    fields: [
      {
        id: "temperature",
        title: "Plages de températures de consigne du réseau",
        type: "choice",
        choices: [
          { name: "[15°C ; 18°C[", value: ":H:5100:H:4200:H:3000" },
          { name: "[18°C ; 20°C]", value: ":H:6400:H:5900:H:4700" },
        ],
      },
      {
        id: "power2",
        title: "Puissance électrique nominale du groupe de production de froid (en kW)",
        type: "number",
      },
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Climatisation hors Data Center", value: "1" },
          { name: "Climatisation Data Center", value: "4.5" },
        ],
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
    startDate: "2024-08-22T01:00:00.000Z",
    history: [
      {
        name: "Freecooling par eau de refroidissement en substitution d'un groupe froid pour la climatisation",
        id: "BAT-TH-156",
        formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-156.pdf",
        type: "Froid",
        fields: [
          {
            id: "temperature",
            title: "Plages de températures de consigne du réseau",
            type: "choice",
            choices: [
              { name: "[15°C ; 18°C[", value: ":H:5100:H:4200:H:3000" },
              { name: "[18°C ; 20°C]", value: ":H:6400:H:5900:H:4700" },
            ],
          },
          {
            id: "power",
            title: "Puissance électrique nominale du groupe de production de froid (en kW)",
            type: "number",
          },
          {
            id: "sector",
            title: "Secteur",
            type: "choice",
            choices: [
              { name: "Climatisation hors Data Center", value: "1" },
              { name: "Climatisation Data Center", value: "4.5" },
            ],
          },
        ],
        attachments: ["Étude de dimensionnement préalable"],
      },
    ],
  },
  {
    name: "Chaudière biomasse collective",
    id: "BAT-TH-157",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-157.pdf",
    type: "Thermique",
    fields: [
      {
        id: "power",
        title: "Puissance de la chaudière",
        type: "choice",
        choices: [
          { name: "Inférieure ou égale à 500 kW", value: "4.8" },
          { name: "Supérieure à 500 kW", value: "3.4" },
        ],
      },
      {
        id: "heat",
        title: "Chaleur nette utile produite par la chaudière biomasse installée en kWh/an",
        type: "number",
      },
    ],
    attachments: ["Étude de dimensionnement préalable"],
  },
  {
    name: "Pompe à chaleur réversible de type air/air (France métropolitaine)",
    id: "BAT-TH-158",
    formUrl: "https://gdceeforms.blob.core.windows.net/v62/BAT-TH-158.pdf",
    type: "Thermique",
    metDomTom: 1,
    fields: [
      {
        id: "type",
        title: "Type de pompe à chaleur",
        type: "choice",
        choices: [
          { name: "PAC de puissance calorifique nominale inférieure ou égale à 12 kW", value: ":H:860:H:760:H:620" },
          { name: "PAC de puissance calorifique nominale supérieure à 12 kW", value: ":H:870:H:770:H:630" },
          { name: "PAC en toiture (« rooftop »)", value: ":H:660:H:540:H:360" },
        ],
      },
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Hôtellerie - restauration", value: "1" },
          { name: "Santé", value: "2" },
          { name: "Enseignement", value: "3" },
          { name: "Bureaux", value: "4" },
          { name: "Commerces", value: "5" },
          { name: "Autres", value: "6" },
        ],
      },
      {
        id: "sector",
        title: "Facteur correctif selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "0.7" },
          { name: "2", value: "1.1" },
          { name: "3", value: "0.8" },
          { name: "4", value: "1.2" },
          { name: "5", value: "0.9" },
          { name: "6", value: "0.7" },
        ],
      },
      {
        id: "area",
        type: "number",
        titles: [
          {
            fieldId: "type",
            fieldValue: ":H:870:H:770:H:630",
            title: "Surface totale chauffée par la PAC (m²)",
          },
          {
            fieldId: "type",
            fieldValue: ":H:860:H:760:H:620",
            title: "Surface totale chauffée par la PAC (m²)",
          },
          {
            fieldId: "type",
            fieldValue: ":H:660:H:540:H:360",
            title: "Surface totale traitée (m²)",
          },
        ],
        showIf: { fieldId: "type", fieldValues: [":H:870:H:770:H:630", ":H:860:H:760:H:620", ":H:660:H:540:H:360"] },
      },
    ],
    attachments: [],
  },
  {
    name: "Raccordement d'un bâtiment tertiaire à un réseau de froid",
    id: "BAT-TH-159",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-159.pdf",
    type: "Froid",
    fields: [
      {
        id: "sector",
        title: "Secteur",
        type: "choice",
        choices: [
          { name: "Data Center", value: "1" },
          { name: "Cafés, hôtels, restaurants", value: "2" },
          { name: "Santé", value: "3" },
          { name: "Enseignement, recherche", value: "4" },
          { name: "Sport, loisirs, culture", value: "5" },
          { name: "Bureaux", value: "6" },
          { name: "Commerces", value: "7" },
          { name: "Autres", value: "8" },
        ],
      },
      {
        id: "sector",
        title: "Montant en kWh cumac selon secteur d'activité",
        type: "multichoice",
        choices: [
          { name: "1", value: "26000" },
          { name: "2", value: "10400" },
          { name: "3", value: "26000" },
          { name: "4", value: "4900" },
          { name: "5", value: "19800" },
          { name: "6", value: "7800" },
          { name: "7", value: "11300" },
          { name: "8", value: "4900" },
        ],
      },
      {
        id: "factor",
        title: "Coefficient multiplicateur",
        type: "value",
        value: ":H:1:H:1.3:H:1.8",
      },
      {
        id: "power",
        title: "Puissance thermique en kW",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Vannes de régulation étanches à servomoteurs économes (France métropolitaine)",
    metDomTom: 1,
    id: "BAT-TH-160",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/BAT-TH-160.pdf",
    type: "Moteur",
    fields: [
      {
        id: "area",
        title: "Surface traitée par les unités terminales (en m²)",
        type: "number",
      },
      {
        id: "type",
        title: "Type d'unité terminale eau/air",
        type: "choice",
        choices: [
          { name: "2 tubes", value: "300" },
          { name: "2 tubes / 2 fils", value: "500" },
          { name: "4 tubes", value: "630" },
        ],
      },
      {
        id: "sector",
        title: "Secteur d'activité des locaux",
        type: "choice",
        choices: [
          { name: "Bureaux", value: "1" },
          { name: "Restauration", value: "1.35" },
          { name: "Hôtellerie", value: "0.94" },
          { name: "Enseignement", value: "1.09" },
        ],
      },
      {
        id: "class",
        title: "Classe du système de gestion technique du bâtiment",
        type: "choice",
        choices: [
          { name: "A", value: "1.42" },
          { name: "B", value: "1.2" },
          { name: "C", value: "1" },
          { name: "D", value: "0.73" },
        ],
      },
    ],
    attachments: [],
    endDate: "2024-09-01T02:00:00.000Z",
  },
  {
    name: "Maintien en température des groupes électrogènes de secours par pompe à chaleur de type air/eau",
    id: "BAT-TH-161",
    formUrl: "https://gdceeforms.blob.core.windows.net/v62/BAT-TH-161.pdf",
    type: "Moteur",
    fields: [
      {
        id: "power",
        title: "Puissance nominale du groupe électrogène équipé d’une PAC pour le maintien en température du moteur",
        type: "choice",
        choices: [
          { name: "800 kW ≤ P ≤ 1 200 kW", value: "167800" },
          { name: "1 200 kW < P ", value: "279600" },
        ],
      },
      {
        id: "generator",
        title: "Nombre de groupe électrogène",
        type: "number",
      },
    ],
    attachments: [],
    startDate: "2024-08-31T02:00:00.000Z",
  },
];
export default batForms;

export const activeForms: string[] = batForms.filter((f) => f.fields.length > 0).map((f) => f.id);
