import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Card, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SettingsContext } from "../data/contexts/SettingsContext";
import { TokenContext } from "../data/contexts/TokenContext";
import { computePeriods, formatCapacity } from "../data/dataConvertors";
import { Obliged, ProjectLightFragment, Rate } from "../data/generated/graphql";
import ProjectsSummary from "../projects/ProjectsSummary";
import BaseCard from "../utils/BaseCard";
import GdAlert from "../utils/GdAlert";
import GdButton from "../utils/GdButton";
import "./Obliged.css";
import RateHistoryDataViz from "./RateHistoryDataViz";
import { TextMaskNumber } from "../utils/TextMasks";
import exportData from "../utils/exportData";

interface YearAndRate {
  years: string[];
  rates: Rate[];
}

const getAllYears = (rateHistory: Rate[]): string[] => {
  const currentYear = new Date().getFullYear();
  const result: string[] = [`${currentYear}`];
  rateHistory.forEach((rh) => {
    if (rh.period !== undefined && rh.period !== null && !result.includes(rh.period.substring(0, 4)))
      result.push(rh.period.substring(0, 4));
  });
  return result;
};

interface ObligedCardProps {
  obliged: Obliged;
  labels: Record<string, string>;
  getToken: () => Promise<string | null>;
  error: (message: string) => void;
}

const ObligedCard: FC<ObligedCardProps> = ({ obliged, labels, getToken, error }) => {
  const [changing, setChanging] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const [rateHistory, setRateHistory] = useState<YearAndRate>({
    years: getAllYears(obliged.rateHistory || []),
    rates: computePeriods(obliged.rateHistory || []),
  });
  const [openMonthlyRateModal, setOpenMonthlyRateModal] = useState(false);
  const { updateRateHistory } = useContext(SettingsContext);
  const { t } = useTranslation("settings");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setRateHistory({ years: rateHistory.years, rates: computePeriods(obliged.rateHistory || []) });
  }, [obliged.rateHistory, rateHistory.years]);

  const exportObligedData = async (csv = false): Promise<void> => {
    (csv ? setIsExportingCsv : setIsExporting)(true);
    const token = await getToken();
    if (token !== null) await exportData(obliged.id, token, error, undefined, undefined, csv);
    (csv ? setIsExportingCsv : setIsExporting)(false);
  };

  const changeRate = (period: string, newRate: string): void => {
    setRateHistory({
      years: rateHistory.years,
      rates: rateHistory.rates.map((rh) => (rh.period === period ? { period, value: newRate } : rh)),
    });
  };

  const changeRateHistory = async (): Promise<void> => {
    const emptyRates = rateHistory.rates.filter((r) => r.value === "");
    if (emptyRates.length > 0) {
      enqueueSnackbar({ message: t("emptyRatesError", { count: emptyRates.length }), variant: "error" });
    } else {
      setChanging(true);
      const result = await updateRateHistory(obliged.id, rateHistory.rates);
      if (!result) {
        enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
      }
      setChanging(false);
      setOpenMonthlyRateModal(false);
    }
  };

  const cancelEditRates = (): void => {
    setOpenMonthlyRateModal(false);
    setRateHistory({ years: rateHistory.years, rates: computePeriods(obliged.rateHistory || []) });
  };

  return (
    <Card
      variant="outlined"
      className="obliged-list-item big-margin-bottom"
      style={{ opacity: obliged.active ? 1 : 0.7 }}>
      <Typography variant="h6" className="margin-bottom">
        {obliged.name}
        {obliged.active ? "" : ` (${labels.deactivated})`}
      </Typography>
      {!labels.rate ? undefined : <Typography className="margin-bottom">{labels.rate}</Typography>}
      <Typography>
        {labels.capacityLeft} {formatCapacity(obliged.capacityLeft || "")} / {formatCapacity(obliged.capacity || "")}
      </Typography>
      {obliged.active ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", alignSelf: "stretch" }}>
            <GdButton
              className="margin-top"
              label={labels.export}
              onClick={() => exportObligedData()}
              isLoading={isExporting}
            />
            <GdButton
              className="margin-top"
              label={labels.exportCSV}
              onClick={() => exportObligedData(true)}
              isLoading={isExportingCsv}
            />
          </div>
          <GdButton
            label={t("monthlyRateHistory")}
            onClick={() => setOpenMonthlyRateModal(true)}
            disabled={false}
            color="secondary"
            className="margin-top"
          />
        </div>
      ) : undefined}
      {(obliged.affectedProjects?.length || 0) > 0 ? (
        <Accordion sx={{ marginTop: "16px" }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography className="margin-top margin-bottom">{labels.projects}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProjectsSummary projects={(obliged.affectedProjects as ProjectLightFragment[]) || []} fromObliged />
          </AccordionDetails>
        </Accordion>
      ) : undefined}
      {obliged.active ? undefined : <Typography className="margin-top">{labels.activate}</Typography>}
      <GdAlert
        title={t("monthlyRateHistory")}
        open={openMonthlyRateModal}
        onClose={cancelEditRates}
        body={
          <div style={{ width: "300px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {rateHistory.years.map((y) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="margin-top margin-bottom">{y}</Typography>
                </AccordionSummary>

                {rateHistory.rates.map((rh) =>
                  rh.period?.substring(0, 4) === y.toString() ? (
                    <AccordionDetails>
                      <TextField
                        value={rh.value}
                        onChange={(e) => changeRate(rh.period || "", e.target.value)}
                        variant="outlined"
                        label={rh.period}
                        sx={{ margin: "8px" }}
                        InputProps={{
                          inputComponent: TextMaskNumber as any,
                        }}
                      />
                    </AccordionDetails>
                  ) : undefined,
                )}
              </Accordion>
            ))}
          </div>
        }
        okButton
        okButtonClick={changeRateHistory}
        okButtonLoading={changing}
      />
    </Card>
  );
};

const ObligedScreen: FC = () => {
  const { t } = useTranslation(["settings", "user"]);
  const { settings } = useContext(SettingsContext);
  const { getToken } = useContext(TokenContext);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <BaseCard>
      <Typography variant="h6" className="margin-bottom">
        {t("obligedList")}
      </Typography>
      <div className="obliged-list-root">
        <RateHistoryDataViz />
        {settings.obliged.map((o) => (
          <ObligedCard
            obliged={o}
            key={o.id}
            labels={{
              deactivated: t("user:userStatus.false"),
              capacityLeft: t("capacityLeft"),
              projects: t("affectedProjects", { count: o.affectedProjects?.length || 0 }),
              activate: t("howToActivateObliged"),
              rate: !o.rate
                ? ""
                : t("obligedRate", {
                    replace: {
                      nbMonth: o.rate.nbMonth,
                      multiplicator: o.rate.multiplicator,
                      maxMargin: o.rate.maxMargin,
                    },
                  }),
              export: t("exportObligedData"),
              exportCSV: t("exportObligedDataCSV"),
            }}
            getToken={getToken}
            error={(msg) => enqueueSnackbar(t("exportError", { replace: { msg } }), { variant: "error" })}
          />
        ))}
      </div>
    </BaseCard>
  );
};

export default ObligedScreen;
